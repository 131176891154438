import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, deleteDoc , collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAK1FvIrAUCiKPHVhWDU3yk8KWeUPlzm9o",
  authDomain: "gestoreschede.firebaseapp.com",
  projectId: "gestoreschede",
  storageBucket: "gestoreschede.appspot.com",
  messagingSenderId: "414870899256",
  appId: "1:414870899256:web:b6e42a4aa46ffa2d3574cd",
  measurementId: "G-B15QJLYMXY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db, doc, deleteDoc,collection, getDocs };
