import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { startOfWeek, format } from "date-fns"; // Assicurati di avere date-fns installato
import { FaEye, FaTimes, FaChartLine, FaArrowLeft } from "react-icons/fa"; // Importa l'icona del grafico
import Lottie from "lottie-react";
import LoadingAnimation from "../../assets/lottie/wired-outline-667-biking-hover-pinch.json";
const ViewProgression = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [progressionData, setProgressionData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openWeeks, setOpenWeeks] = useState([]); // Per gestire l'espansione delle settimane
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0); // Indice settimana corrente
  const [activeWeekIndex, setActiveWeekIndex] = useState(null); // Stato per gestire il box attivo

  useEffect(() => {
    const fetchData = async () => {
      try {
        const progressionRef = doc(db, "progressione", id);
        const progressionSnap = await getDoc(progressionRef);

        if (progressionSnap.exists()) {
          const data = progressionSnap.data();
          const groupedData = groupByWeek(data.progressione); // Raggruppiamo i dati per settimana
          setProgressionData(groupedData);
        } else {
          setError(
            "Nessun documento di progressione trovato per l'utente.\nTi sto portando alla HOME."
          );
          setTimeout(() => {
            navigate("/home");
          }, 2000);
        }

        const userRef = doc(db, "users", id);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setUserData(userSnap.data());
        } else {
          setError("Nessun utente trovato");
        }
      } catch (error) {
        setError("Errore nel recupero dei dati");
        console.error("Errore nel recupero dei dati:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Funzione per raggruppare i dati per settimana
  const groupByWeek = (data) => {
    const weeks = {};

    data.forEach((dayData) => {
      const date = new Date(dayData.date);
      const weekStart = startOfWeek(date); // Raggruppiamo per settimana
      const weekKey = format(weekStart, "yyyy-MM-dd");

      if (!weeks[weekKey]) {
        weeks[weekKey] = {
          weekStart,
          days: [],
        };
      }

      weeks[weekKey].days.push(dayData);
    });

    return Object.entries(weeks)
      .map(([key, value]) => ({
        weekStart: value.weekStart,
        days: value.days,
      }))
      .sort((a, b) => b.weekStart - a.weekStart); // Ordine decrescente
  };

  const toggleWeek = (index) => {
    setOpenWeeks((prev) =>
      prev.includes(index)
        ? prev.filter((week) => week !== index)
        : [...prev, index]
    );
    // Imposta il box attivo
    setActiveWeekIndex(activeWeekIndex === index ? null : index);
  };

  // Funzioni per navigare tra le settimane
  const handlePrevious = () => {
    if (currentWeekIndex > 0) {
      setCurrentWeekIndex(currentWeekIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentWeekIndex < Math.floor((progressionData.length - 1) / 5)) {
      setCurrentWeekIndex(currentWeekIndex + 1);
    }
  };

  if (error)
    return (
      <div className="flex flex-col items-center justify-center min-h-screen text-center text-red-500">
        <p>{error.split("\n")[0]}</p>
        <p>{error.split("\n")[1]}</p>
      </div>
    );

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Lottie
          animationData={LoadingAnimation}
          loop
          autoplay
          style={{ width: 200, height: 200 }}
        />
        <p className="mt-4 text-gray-600">Caricamento in corso...</p>{" "}
        {/* Messaggio di caricamento */}
      </div>
    );
  }

  // Calcola le settimane da visualizzare
  const start = currentWeekIndex * 5;
  const displayedWeeks = progressionData.slice(start, start + 5);

  return (
    <div className="p-6 bg-gray-100 min-h-screen   ">
      {/* Freccia per tornare alla Home */}
      <button
        onClick={() => navigate("/home")}
        className="flex items-center text-blue-500 hover:text-blue-700 mr-auto  md:hidden" // Mostra solo su schermi 'md' e più grandi
      >
        <FaArrowLeft size={24} />
        <span className="ml-1">Home</span>
      </button>
      <div className="flex justify-center mb-6">
        <div
          className="bg-white p-4 rounded-full shadow-lg"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
        >
          <FaChartLine size={48} className="text-blue-500" />
        </div>
      </div>

      <div className="flex items-center mb-4 ">
        {/* Freccia per tornare alla Home */}

        {/* Titolo centrato */}
        <h1 className="flex-grow text-center font-bold text-2xl  md:-ml-0">
          {" "}
          {/* Aggiunto margine negativo */}
          {userData?.nome.toUpperCase()} {userData?.cognome.toUpperCase()}
        </h1>
      </div>

      {displayedWeeks.length > 0 ? (
        <div className="space-y-4 max-w-lg mx-auto ">
          <div className="text-center">
            <label>
              Coach qui visualizzi tutti gli allenamenti divisi in{" "}
              <strong>settimane</strong>, a partire dall'
              <strong>ultima inserita</strong>
            </label>
          </div>
          {/* Imposta la larghezza massima e centra il contenitore */}
          {displayedWeeks.map((weekData, index) => (
            <div
              key={index}
              className={`bg-white shadow-xl rounded-lg p-4 border border-blue-400  ${
                activeWeekIndex === index ? "border-2 border-blue-500" : ""
              }`}
            >
              <div
                onClick={() => toggleWeek(index)}
                className="flex justify-between items-center cursor-pointer hover:bg-gray-200 p-2 rounded "
              >
                <h2 className="text-xl font-semibold text-black">
                  Settimana da: {format(weekData.weekStart, "dd MMM yyyy")}
                </h2>
                <span>
                  {openWeeks.includes(index) ? (
                    <FaTimes style={{ width: 25, height: 25, color: "red" }} />
                  ) : (
                    <FaEye
                      style={{ width: 25, height: 25 }}
                      className="text-blue-500"
                    />
                  )}
                </span>
              </div>

              {openWeeks.includes(index) && (
                <div className="mt-4">
                  {weekData.days.map((dayData, dayIndex) => (
                    <div
                      key={dayIndex}
                      className="border-b border-gray-300 pb-2 mt-4"
                    >
                      <div className="flex items-center mb-2">
                        <h3 className="text-xl font-semibold text-blue-600">
                          {dayData.date} - Giorno {dayData.day}
                        </h3>
                      </div>
                      <div className="space-y-2">
                        {dayData.exercises && dayData.exercises.length > 0 ? (
                          dayData.exercises.map((exercise, idx) => (
                            <div key={idx} className="text-gray-800 pb-2">
                              <h3 className="text-lg font-bold">
                                {exercise.name.toUpperCase()}
                              </h3>
                              <ul className="list-disc list-inside text-md text-gray-600 mt-2">
                                {exercise.series.map((serie, sIndex) => (
                                  <li key={sIndex} className="ml-4">
                                    Serie {sIndex + 1}: {serie.reps} ripetizioni
                                    da {serie.kg} kg
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))
                        ) : (
                          <p className="text-gray-500">
                            Nessun esercizio disponibile per questo giorno.
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">
          Nessun dato di progressione disponibile.
        </p>
      )}

      <div className="flex flex-col items-center mt-4 pt-6 pb-4 max-w-lg mx-auto ">
        {/* Div per i pulsanti Precedente e Successivo */}
        <div className="flex justify-between w-full">
          <button
            onClick={handlePrevious}
            disabled={currentWeekIndex === 0}
            className="px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded focus:outline-none disabled:bg-gray-300"
          >
            Precedente
          </button>
          <button
            onClick={handleNext}
            disabled={
              currentWeekIndex >= Math.floor((progressionData.length - 1) / 5)
            }
            className="px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded focus:outline-none disabled:bg-gray-300"
          >
            Successivo
          </button>
        </div>

        {/* Div per il pulsante Torna alla Home */}
        <div className="mt-6 hidden md:block">
          <button
            onClick={() => navigate("/home")}
            className="px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded focus:outline-none disabled:bg-gray-300"
          >
            <span className="ml-1">Torna alla Home</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewProgression;
