import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doCreateUserWithEmailAndPassword } from '../../../firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { db, auth } from '../../../firebase/firebase';

const RegisterStepTwo = ({ personalInfo, domandeInfo, onBack }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessage("Le password non corrispondono");
            return;
        }
       

        if (!isRegistering) {
            setIsRegistering(true);
            try {
                await doCreateUserWithEmailAndPassword(email, password);
                const user = auth.currentUser;
                if (user) {
                    await setDoc(doc(db, "users", user.uid), {
                        email: user.email,
                        ...personalInfo,
                        ...domandeInfo
                    });
                    navigate('/home');
                }
            } catch (error) {
                setErrorMessage(error.message);
                setIsRegistering(false);
            }
        }
    };

    return (
        <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border border-blue-600 rounded-xl">
            <div className="text-center mb-6">
                <div className="mt-2">
                    <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Completa la tua registrazione</h3>
                </div>
            </div>
            <form onSubmit={onSubmit} className="space-y-4">
                <div>
                    <label className="text-sm text-gray-600 font-bold">Email</label>
                    <input
                        type="email"
                        placeholder='Esempio@email.it'
                        autoComplete='email'
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
                    />
                </div>
                <div>
                    <label className="text-sm text-gray-600 font-bold">Password</label>
                    <input
                        disabled={isRegistering}
                        placeholder='Inserisci la tua password'
                        type="password"
                        autoComplete='new-password'
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
                    />
                </div>
                <div>
                    <label className="text-sm text-gray-600 font-bold">Conferma Password</label>
                    <input
                        disabled={isRegistering}
                        type="password"
                        placeholder='Inserisci la tua password'
                        autoComplete='off'
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
                    />
                </div>
                {errorMessage && (
                    <span className='text-red-600 font-bold'>{errorMessage}</span>
                )}
                <div className="mt-20">
                <button
                    type="submit"
                    disabled={isRegistering}
                    className={`w-full px-4 py-4 text-white font-medium rounded-lg ${isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 hover:shadow-xl transition duration-300'}`}
                >
                    {isRegistering ? 'Confermando...' : 'Conferma'}
                </button>
                <button
                    type="button"
                    onClick={onBack}
                    disabled={isRegistering}
                    className={`w-full px-4 py-4 mt-2 text-white font-medium rounded-lg ${isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 hover:shadow-xl transition duration-300'}`}
                >
                    Torna indietro
                </button>
                </div>
            </form>
        </div>
    );
};

export default RegisterStepTwo;
