import React, { useState } from "react";
import { Link } from "react-router-dom";
import WeeklyWorkoutsSelector from "../../WeeklyWorkoutSelector";
import HeightSelector from "../../HeightSelector";
import WeightSelector from "../../WeightSelector";

const RegisterStepOne = ({ onNext }) => {
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [peso, setPeso] = useState(70); // Imposta un valore predefinito
  const [altezza, setAltezza] = useState(160); // Imposta un valore predefinito
  const [giorni, setGiorni] = useState(1);
  const [sesso, setSesso] = useState(""); // Imposta un valore predefinito vuoto
  const [infortunio, setInfortunio] = useState(""); // Imposta un valore predefinito vuoto


  const handleNext = (e) => {
    e.preventDefault();
    onNext({ nome, cognome, sesso, peso, altezza, giorni, infortunio });
  };

  return (
    <div className="w-96 text-gray-600 space-y-5 p-4 pb-10  shadow-xl border rounded-xl">
      <div className="text-center mb-6">
        <div className="mt-2">
          <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">
            Personal Information
          </h3>
        </div>
      </div>
      <form onSubmit={handleNext} className="space-y-4">
        <div>
          <label className="text-sm text-gray-600 font-bold">Nome</label>
          <input
            type="text"
            placeholder="Inserisci il tuo nome"
            required
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
          />
        </div>
        <div>
          <label className="text-sm text-gray-600 font-bold">Cognome</label>
          <input
            type="text"
            placeholder="Inserisci il tuo cognome"
            required
            value={cognome}
            onChange={(e) => setCognome(e.target.value)}
            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
          />
        </div>
        <div>
          <label className="text-sm text-gray-600 font-bold">Sesso</label>
          <div className="flex space-x-4 mt-2">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="sesso"
                value="Maschile"
                checked={sesso === "Maschile"}
                onChange={(e) => setSesso(e.target.value)}
                className="form-radio text-blue-600"
                required
              />
              <span>Maschile</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="sesso"
                value="Femminile"
                checked={sesso === "Femminile"}
                onChange={(e) => setSesso(e.target.value)}
                className="form-radio text-blue-600"
              />
              <span>Femminile</span>
            </label>
          </div>
        </div>
        <div>
          <label className="text-sm text-gray-600 font-bold">Peso</label>
          <WeightSelector value={peso} onChange={setPeso} />
        </div>
        <div>
          <label className="text-sm text-gray-600 font-bold">Altezza</label>
          <HeightSelector value={altezza} onChange={setAltezza} />
        </div>
        <div>
          <label className="text-sm text-gray-600 font-bold">
            Allenamenti settimanali
          </label>
          <WeeklyWorkoutsSelector value={giorni} onChange={setGiorni} />
        </div>
       
       
        
        
        <button
          type="submit"
          className="w-full px-4 py-4 text-white font-medium rounded-lg bg-blue-600 hover:bg-blue-700 hover:shadow-xl transition duration-300"
        >
          Avanti
        </button>
      </form>
      <div className="text-sm text-center">
        Hai già un account?{" "}
        <Link
          to={"/login"}
          className="text-center text-sm hover:underline font-bold"
        >
          Continua
        </Link>
      </div>
    </div>
  );
};

export default RegisterStepOne;
