import React from 'react';

const WeeklyAdvice = ({ advice, onAdviceChange, numberAdvice }) => {
  return (
    <div className="mb-4">
      <h1 className='font-bold text-xl mb-2 -ml-2'>Consiglio settimana {numberAdvice}</h1>
      <textarea
        value={advice}
        onChange={(e) => onAdviceChange(e.target.value)}
        placeholder="Inserisci il consiglio settimanale"
        rows="4"
        className="md:w-3/4 -ml-2 px-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent resize-none"
      />
    </div>
  );
};

export default WeeklyAdvice;
