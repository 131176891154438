// UserDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';

const UserDetails = () => {
    const { id } = useParams();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            try{
            const docRef = doc(db, "users", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUser(docSnap.data());
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error('Error fetching user:', error);
          }
        };

        fetchUser();
    }, [id]);

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className='mt-12'>
            <h2>{user.nome} {user.cognome}</h2>
            <p>Altre informazioni: {user.altreInformazioni}</p>
            {/* Aggiungi qui altre informazioni che vuoi mostrare */}
        </div>
    );
};

export default UserDetails;