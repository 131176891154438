import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { FaCalendarAlt, FaArrowLeft } from "react-icons/fa";
import Lottie from "lottie-react";
import LoadingAnimation from "../../assets/lottie/wired-outline-667-biking-hover-pinch.json";
import Empty from "../../assets/lottie/wired-flat-1140-error-hover-oscillate.json";

const ViewIntegration = () => {
  const { id } = useParams();
  const [integrationData, setIntegrationData] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchIntegration = async () => {
    try {
      const docRef = doc(db, "Integrazioni", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setIntegrationData(data);
        setTimestamp(data.timestamp?.toDate().toLocaleString() || "N/A");
      } else {
        console.log("Documento non trovato!");
        setError("Documento non trovato!");
      }
    } catch (error) {
      console.error("Errore durante il recupero dell'integrazione: ", error);
      setError("Errore durante il recupero dei dati");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIntegration();
  }, [id]);

  const goHome = () => {
    navigate("/home");
  };

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen text-center text-red-500">
        <p>{error}</p>
        <button onClick={goHome} className="mt-4 text-indigo-600">
          Torna alla Home
        </button>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Lottie
          animationData={LoadingAnimation}
          loop
          autoplay
          style={{ width: 200, height: 200 }}
        />
        <p className="mt-4 text-gray-600">Caricamento in corso...</p>
      </div>
    );
  }

  // Gruppo delle integrazioni per momento della giornata
  const groupIntegrationsByTimeOfDay = (integrations) => {
    return integrations.reduce((acc, integration) => {
      const timeOfDay = integration.timeOfDay; // Es. "Mattina", "Post Workout", ecc.
      if (!acc[timeOfDay]) {
        acc[timeOfDay] = []; // Crea un array per il momento della giornata se non esiste
      }
      acc[timeOfDay].push(integration); // Aggiungi l'integrazione all'array corrispondente
      return acc;
    }, {});
  };

  const groupedIntegrations = groupIntegrationsByTimeOfDay(integrationData.settimana);

  return (
    <div className="flex flex-col items-center px-4 py-8">
      <div className="w-full max-w-2xl mb-6">
        <button
          onClick={goHome}
          className="flex items-center text-indigo-600 hover:text-indigo-700 font-semibold"
        >
          <FaArrowLeft className="mr-2" /> Torna alla Home
        </button>
      </div>

      <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center text-indigo-600">
        Dettagli Integrazione
      </h2>

      {timestamp && (
        <div className="mb-4 text-gray-600">
          <FaCalendarAlt className="inline-block mr-2 text-indigo-600" />
          <strong>Ultimo aggiornamento:</strong> {timestamp}
        </div>
      )}

      {Object.keys(groupedIntegrations).length > 0 ? (
        <div className="bg-white shadow-lg rounded-lg w-full max-w-2xl p-6 border border-gray-300">
          {Object.keys(groupedIntegrations).map((timeOfDay) => (
            <div key={timeOfDay} className="mb-4">
              <h3 className="text-2xl font-semibold text-indigo-700 mb-2">
                {timeOfDay}
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {groupedIntegrations[timeOfDay].map((integration, index) => (
                  <div key={index} className="bg-indigo-100 p-4 rounded-md shadow-md">
                    <h4 className="text-xl font-bold text-indigo-700">
                      {integration.integrationName}
                    </h4>
                    <p className="text-gray-700 mt-2">
                      <strong>Peso:</strong> {integration.weight}g
                    </p>
                    <p className="text-gray-700">
                      <strong>Momento della giornata:</strong> {integration.timeOfDay}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center mt-8">
          <Lottie
            animationData={Empty}
            loop
            autoplay
            style={{ width: 250, height: 250 }}
          />
          <p className="mt-4 text-gray-600">Nessuna integrazione trovata.</p>
        </div>
      )}
    </div>
  );
};

export default ViewIntegration;
