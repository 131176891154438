import { React } from 'react';
import PropTypes from 'prop-types';
import '../style.css'
const Box = ({ title, backgroundColor, buttonText, onButtonClick, description, icon: Icon }) => (
  <div 
    className="box" 
    style={{
      backgroundColor, 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      textAlign: 'center', 
      padding: '20px', 
      maxWidth: '400px', // Limita la larghezza per i dispositivi più grandi
      margin: 'auto', // Centra la box orizzontalmente
      marginBottom:'10px'
    }}
  >
    {Icon && (
    <div
    className="bg-[#FFFFFF3B] rounded-full flex justify-center items-center mb-2.5 w-16 h-16 sm:w-16 sm:h-16 md:w-24 md:h-24" // Usa classi responsive per la dimensione
  >
   <Icon
  className="icon text-white text-3xl w-12 h-12 sm:text-4xl sm:w-16 sm:h-16  md:w-18 md:h-18"
/>
  </div>
    )}

<h2 
  className="text-white font-bold text-2xl sm:text-3xl md:text-4xl mb-4"
>
  {title}
</h2>

    
    <div 
      className="flex flex-grow" 
      style={{
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        marginBottom: '20px', // Spazio tra la descrizione e il bottone
      }}
    >
      <p 
        style={{
          fontSize: '16px', 
          lineHeight: '1.5', 
          color: 'white', 
          maxWidth: '300px', // Imposta un limite alla larghezza del testo per renderlo più leggibile
        }}
      >
        {description}
      </p>
    </div>

    <button
      className="bottoneApri"
      style={{
        backgroundColor: '#fff',
        color: 'black',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
      }}
      onClick={onButtonClick}
    >
      {buttonText}
    </button>
  </div>
);

Box.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
};

export default Box;
