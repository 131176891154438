import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCookieBite,
  faDrumstickBite,
  faWheatAwn,
} from "@fortawesome/free-solid-svg-icons";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Swal from "sweetalert2";
import Header from "../header";
import EditPasti from "./EditPasti";
const EditNutrition = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [nutritionData, setNutritionData] = useState({
    settimana1: {
      giorniOn: { carbo: "", proteine: "", grassi: "" },
      giorniOff: { carbo: "", proteine: "", grassi: "" },
    },
    settimana3: {
      giorniOn: { carbo: "", proteine: "", grassi: "" },
      giorniOff: { carbo: "", proteine: "", grassi: "" },
    },
    settimana5: {
      giorniOn: { carbo: "", proteine: "", grassi: "" },
      giorniOff: { carbo: "", proteine: "", grassi: "" },
    },
  });
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState("settimana1"); // Stato per la settimana selezionata
  const navigate = useNavigate();
  const [pastiOn, setPastiOn] = useState([]);
  const [pastiOff, setPastiOff] = useState([]);

  useEffect(() => {
    const fetchUserDataAndNutrition = async () => {
      const userDocRef = doc(db, "users", id);
      const dietaDocRef = doc(db, "dieta", id);

      try {
        // Recupera i dati dell'utente
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data());
        } else {
          await Swal.fire({
            title: "Errore",
            text: "L'utente non esiste. Contatta l'assistenza.",
            icon: "error",
            confirmButtonText: "OK",
          });
          navigate("/home");
          setLoading(false);
          return;
        }

        // Recupera i dati della dieta
        const dietaDocSnap = await getDoc(dietaDocRef);
        if (dietaDocSnap.exists()) {
            const data = dietaDocSnap.data();
            setNutritionData({
                settimana1: data.settimana1 || {
                    giorniOn: { carbo: "", proteine: "", grassi: "" },
                    giorniOff: { carbo: "", proteine: "", grassi: "" },
                },
                settimana3: data.settimana3 || {
                    giorniOn: { carbo: "", proteine: "", grassi: "" },
                    giorniOff: { carbo: "", proteine: "", grassi: "" },
                },
                settimana5: data.settimana5 || {
                    giorniOn: { carbo: "", proteine: "", grassi: "" },
                    giorniOff: { carbo: "", proteine: "", grassi: "" },
                },
            });
            setAdditionalNotes(data.noteAggiuntive || "");
        
            // Imposta pastiOn e pastiOff per la settimana selezionata
            setPastiOn(data[selectedWeek]?.pastiOn || []); // Pasti ON
            setPastiOff(data[selectedWeek]?.pastiOff || []); // Pasti OFF
        } else {
          // Inizializza un nuovo documento se non esiste
          await setDoc(dietaDocRef, {
            settimana1: {
              giorniOn: { carbo: "", proteine: "", grassi: "" },
              giorniOff: { carbo: "", proteine: "", grassi: "" },
            },
            settimana3: {
              giorniOn: { carbo: "", proteine: "", grassi: "" },
              giorniOff: { carbo: "", proteine: "", grassi: "" },
            },
            settimana5: {
              giorniOn: { carbo: "", proteine: "", grassi: "" },
              giorniOff: { carbo: "", proteine: "", grassi: "" },
            },
            noteAggiuntive: "",
            pastiOn: "",
            pastiOff: "", // Inizializza pasti come array vuoti
          });
          setNutritionData({
            settimana1: {
              giorniOn: { carbo: "", proteine: "", grassi: "" },
              giorniOff: { carbo: "", proteine: "", grassi: "" },
            },
            settimana3: {
              giorniOn: { carbo: "", proteine: "", grassi: "" },
              giorniOff: { carbo: "", proteine: "", grassi: "" },
            },
            settimana5: {
              giorniOn: { carbo: "", proteine: "", grassi: "" },
              giorniOff: { carbo: "", proteine: "", grassi: "" },
            },
          });
          setAdditionalNotes("");
          setPastiOn([]); // Inizializza pasti ON vuoti
          setPastiOff([]); // Inizializza pasti OFF vuoti
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching documents: ", error);
        setLoading(false);
      }
    };

    fetchUserDataAndNutrition();
  }, [id, navigate]);

  const handleNutritionChange = (e, dayType, nutrient) => {
    const { value } = e.target;
    setNutritionData((prevData) => ({
      ...prevData,
      [selectedWeek]: {
        ...prevData[selectedWeek],
        [dayType]: {
          ...prevData[selectedWeek][dayType],
          [nutrient]: value,
        },
      },
    }));
  };

  const handleAdditionalNotesChange = (e) => {
    setAdditionalNotes(e.target.value);
  };

  const handleWeekChange = (week) => {
    Swal.fire({
      title: "Sei sicuro di cambiare settimana?",
      text: "Le modifiche non salvate andranno perse!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, ho già salvato!",
      cancelButtonText: "Annulla",
    }).then((result) => {
      if (result.isConfirmed) {
        setSelectedWeek(week); // Cambia la settimana selezionata
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dietaDocRef = doc(db, "dieta", id);

    // Copia dei dati nutrizionali correnti per tutte le settimane
    const updatedData = {
        [selectedWeek]: {
            ...nutritionData[selectedWeek],
            pastiOn: pastiOn,   // Passa i pasti per i giorni ON
            pastiOff: pastiOff, // Passa i pasti per i giorni OFF
        },
        noteAggiuntive: additionalNotes,
    };

    try {
      // Mostra un messaggio di conferma all'utente
      const result = await Swal.fire({
        title: "Sei sicuro di salvare?",
        text: "Questa azione è irreversibile!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sì!",
        cancelButtonText: "Annulla",
      });

      // Controlla se l'utente ha confermato
      if (result.isConfirmed) {
        // Salva i dati aggiornati per la settimana selezionata
        await updateDoc(dietaDocRef, updatedData);
        Swal.fire("Dati aggiornati con successo!", "", "success");
        setTimeout(() => navigate("/home"), 1500);
      } 
    } catch (error) {
      Swal.fire("Errore durante l'aggiornamento dei dati", "", "error");
      console.error("Error updating document: ", error);
    }
};


  if (loading)
    return (
      <p className="flex items-center justify-center h-full">Caricamento...</p>
    );

  return (
    <div className=" px-4 py-16 mt-4">
      <Header isVisible={true} /> {/* Header sempre visibile */}
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">
        Assegna una dieta
      </h1>
      {/* Pulsanti per scegliere la settimana */}
      <div className="text-center mb-6">
        <button
          onClick={() => handleWeekChange("settimana1")}
          className={`px-6 py-2 rounded-l-lg ${
            selectedWeek === "settimana1"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          }`}
        >
          Settimana 1-2
        </button>
        <button
          onClick={() => handleWeekChange("settimana3")}
          className={`px-6 py-2 ${
            selectedWeek === "settimana3"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          }`}
        >
          Settimana 3-4
        </button>
        <button
          onClick={() => handleWeekChange("settimana5")}
          className={`px-6 py-2 rounded-r-lg ${
            selectedWeek === "settimana5"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          }`}
        >
          Settimana 5-6
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className="mx-auto bg-white p-6 rounded-lg shadow-md"
      >
        <div className="flex justify-center mb-4">
          <span className="text-gray-700 text-xl mb-2 mr-2">
            Stai assegnando a:{" "}
          </span>
          <span className="font-bold text-xl mr-2">
            {userData.nome.toUpperCase()} {userData.cognome.toUpperCase()}
          </span>
          <div className="text-xl">
            Peso: <span className="text-xl font-bold mr-2">{userData.peso} kg</span>
          </div>
          <div className="text-xl">
            Altezza:{" "}
            <span className="text-xl font-bold">{userData.altezza} cm</span>
          </div>
        </div>

        {/* Contenitore per la tabella */}
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center">
            <div className="w-full max-w-6xl px-4 py-2">
              <div className="font-bold mb-2 text-2xl">
                Inserisci i valori nutrizionali
              </div>
              <table className="min-w-full border-collapse border border-gray-400 text-lg">
                <thead>
                  <tr>
                    <th className="border text-xl border-gray-400 px-4 py-2">
                      Giorni
                    </th>
                    <th
                      className="border text-xl border-gray-400 text-amber-500 py-2 px-4"
                      style={{ width: "120px" }}
                    >
                      Carbo{" "}
                      <FontAwesomeIcon
                        icon={faWheatAwn}
                        size="xs"
                        className="ml-1"
                      />
                    </th>
                    <th
                      className="border text-xl border-gray-400 text-lime-500 py-2 px-4"
                      style={{ width: "140px" }}
                    >
                      Proteine{" "}
                      <FontAwesomeIcon
                        icon={faDrumstickBite}
                        size="xs"
                        className="ml-1"
                      />
                    </th>
                    <th
                      className="border text-xl border-gray-400 text-rose-500 py-2 px-4"
                      style={{ width: "130px" }}
                    >
                      Grassi{" "}
                      <FontAwesomeIcon
                        icon={faCookieBite}
                        size="xs"
                        className="ml-1"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border text-xl border-gray-400 px-4 py-2">
                      Giorni ON
                    </td>
                    <td className="border border-gray-400 px-4 py-2">
                      <input
                        type="text"
                        value={nutritionData[selectedWeek].giorniOn.carbo}
                        onChange={(e) =>
                          handleNutritionChange(e, "giorniOn", "carbo")
                        }
                        className="w-full border rounded py-2 px-2 text-lg"
                      />
                    </td>
                    <td className="border border-gray-400 px-4 py-2">
                      <input
                        type="text"
                        value={nutritionData[selectedWeek].giorniOn.proteine}
                        onChange={(e) =>
                          handleNutritionChange(e, "giorniOn", "proteine")
                        }
                        className="w-full border rounded py-2 px-2 text-lg"
                      />
                    </td>
                    <td className="border border-gray-400 px-4 py-2">
                      <input
                        type="text"
                        value={nutritionData[selectedWeek].giorniOn.grassi}
                        onChange={(e) =>
                          handleNutritionChange(e, "giorniOn", "grassi")
                        }
                        className="w-full border rounded py-2 px-2 text-lg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="border text-xl border-gray-400 px-4 py-2">
                      Giorni OFF
                    </td>
                    <td className="border border-gray-400 px-4 py-2">
                      <input
                        type="text"
                        value={nutritionData[selectedWeek].giorniOff.carbo}
                        onChange={(e) =>
                          handleNutritionChange(e, "giorniOff", "carbo")
                        }
                        className="w-full border rounded py-2 px-2 text-lg"
                      />
                    </td>
                    <td className="border border-gray-400 px-4 py-2">
                      <input
                        type="text"
                        value={nutritionData[selectedWeek].giorniOff.proteine}
                        onChange={(e) =>
                          handleNutritionChange(e, "giorniOff", "proteine")
                        }
                        className="w-full border rounded py-2 px-2 text-lg"
                      />
                    </td>
                    <td className="border border-gray-400 px-4 py-2">
                      <input
                        type="text"
                        value={nutritionData[selectedWeek].giorniOff.grassi}
                        onChange={(e) =>
                          handleNutritionChange(e, "giorniOff", "grassi")
                        }
                        className="w-full border rounded py-2 px-2 text-lg"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <EditPasti
            selectedWeek={selectedWeek}  // Assicurati di passare selectedWeek

          pastiOn={pastiOn}
          setPastiOn={setPastiOn}
          pastiOff={pastiOff}
          setPastiOff={setPastiOff}
        />

        {/* Note aggiuntive */}
        <div className="mt-4">
          <label htmlFor="noteAggiuntive" className="text-xl font-bold mb-2">
            Note aggiuntive:
          </label>
          <textarea
            id="noteAggiuntive"
            value={additionalNotes}
            onChange={handleAdditionalNotesChange}
            className="w-full border rounded py-1 px-1 mt-1 text-xl"
          ></textarea>
        </div>

        <div className="mt-6 text-center space-x-4">
        <button
            onClick={() => navigate("/home")}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-xl rounded"
          >
            Torna alla Home
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white text-xl rounded"
          >
            Salva
          </button>
         
        </div>
      </form>
    </div>
  );
};

export default EditNutrition;