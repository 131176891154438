import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';
import { doSignOut } from '../../firebase/auth';
import logo from "../../assets/logo.jpg";
import { FaArrowLeft } from 'react-icons/fa';

const Header = ({ isVisible }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    // Verifica se la pagina corrente è la home
    const isHomePage = location.pathname === '/home';

    const handleSidebarToggle = () => {
        setIsSidebarOpen(prevState => !prevState); // Toggle stato della sidebar
    };

    const handleLogout = async () => {
        const result = await Swal.fire({
            title: 'Sei sicuro di voler disconnetterti?',
            text: "Le modifiche non salvate andranno perse!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sì, disconnettiti!',
            cancelButtonText: 'Annulla'
        });

        if (result.isConfirmed) {
            localStorage.clear();
            await doSignOut();
            navigate('/login');
        }
    };

    return (
        <div className={`header ${isVisible ? 'header-visible' : 'header-hidden'}`}>
            <nav className='flex items-center justify-between w-full fixed top-0 left-0 h-16 border-b coloreCustomHeader'>
                {!isHomePage && (
                    <button
                        className="btn btn-link d-flex align-items-center text-white me-3 ml-4"
                        onClick={() => navigate('/home')}
                    >
                        <FaArrowLeft size={24} />
                    </button>
                )}
                {isHomePage && (
                    <button className="sidebar-toggle" onClick={handleSidebarToggle}>
                        <div className="sidebar-toggle-line"></div>
                        <div className="sidebar-toggle-line"></div>
                        <div className="sidebar-toggle-line"></div>
                    </button>
                )}
                <div className='text-black text-2xl font-bold'>
                    MD22
                </div>
                <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center mr-2">
                    <img src={logo} alt="Logo" className="w-10 h-9 rounded-full" />
                </div>
            </nav>
            <div className='ContenutoSidebar'>
                <div className={`sidebar ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                    <button className="close-sidebar" onClick={handleSidebarToggle}>×</button>
                    <ul>
                        <li><a href="#home" onClick={handleLogout}>Logout</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;
