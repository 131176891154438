import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Swal from "sweetalert2";
import WeeklyWorkoutsSelector from '../WeeklyWorkoutSelector';
import HeightSelector from '../HeightSelector';
import WeightSelector from '../WeightSelector';

const EditUser = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setUserData({
          ...data,
          peso: parseValue(data.peso),
          altezza: parseValue(data.altezza),
          giorni: data.giorni || 1
        });
        setLoading(false);
      } else {
        console.error("No such document!");
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const parseValue = (value) => {
    if (typeof value === 'string') {
      return parseInt(value.replace(/\D/g, ''), 10);
    }
    return value;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleWeightChange = (value) => {
    setUserData((prevData) => ({
      ...prevData,
      peso: `${value} kg`,
    }));
  };

  const handleHeightChange = (value) => {
    setUserData((prevData) => ({
      ...prevData,
      altezza: `${value} cm`,
    }));
  };

  const handleWorkoutsChange = (value) => {
    setUserData((prevData) => ({
      ...prevData,
      giorni: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "users", id);

    try {
      await updateDoc(docRef, {
        ...userData,
        peso: userData.peso,
        altezza: userData.altezza,
        giorni: userData.giorni
      });
      Swal.fire("Dati aggiornati con successo!", "", "success");
      setTimeout(() => {
        navigate("/home");
      }, 3000); // Naviga dopo 1.5 secondi
    } catch (error) {
      Swal.fire("Errore durante l'aggiornamento dei dati", "", "error");
      console.error("Error updating document: ", error);
    }
  };

  if (loading) {
    return <p>Caricamento...</p>;
  }

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">
        Modifica Utente
      </h1>
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md"
      >
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Nome
          </label>
          <input
            type="text"
            name="nome"
            value={userData.nome || ""}
            readOnly
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Cognome
          </label>
          <input
            type="text"
            name="cognome"
            value={userData.cognome || ""}
            readOnly
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="text-sm text-gray-600 font-bold">Peso</label>
          <WeightSelector value={parseValue(userData.peso || "70 kg")}  onChange={handleWeightChange} />
        </div>
        <div className="mb-4">
          <label className="text-sm text-gray-600 font-bold">Altezza</label>
          <HeightSelector value={parseValue(userData.altezza || "160 cm")}  onChange={handleHeightChange} />
        </div>
        <div className="mb-4">
          <label className="text-sm text-gray-600 font-bold">
            Allenamenti settimanali
          </label>
          <WeeklyWorkoutsSelector value={userData.giorni || 1} onChange={handleWorkoutsChange} />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Salva Modifiche
        </button>
      </form>
    </div>
  );
};

export default EditUser;
{/*  
                DA AGGIUNGERE CHE SE CLICCO SU UNA RIGA DELLA TABELLA SI APRE LA VISUALIZZAZIONE DELLE INFROMAZIONI
                <Link 
                    to={`/edit-user/${user.id}`} 
                    className="text-blue-500 hover:text-blue-700 focus:outline-none"
                    aria-label="Edit User"
                  >
                    <FaEdit size={20} />
                  </Link>
                  */}