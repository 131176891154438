import React from "react";
import { useNavigate } from "react-router-dom";
import { FaPills, FaApple } from "react-icons/fa";
const MiddleModalNutrition = ({ id, closeModal }) => {
  const navigate = useNavigate();
  // Funzione per navigare verso la pagina delle integrazioni
  const goToEditIntegration = () => {
    navigate(`/edit-integration/${id}`);
    closeModal();
  };
  // Funzione per navigare verso la pagina della nutrizione
  const goToEditNutrition = () => {
    navigate(`/edit-nutrition/${id}`);
    closeModal();
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-20 w-[70%] max-w-lg rounded-md shadow-lg">
        <h2 className="flex justify-center text-3xl font-bold text-indigo-600 mb-20">
          Scegli una delle opzioni
        </h2>
        <div className="flex gap-2 justify-center">
          {/* Bottone per Edit Integration */}
          <button
            onClick={goToEditIntegration}
            className="flex flex-col items-center justify-center bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-6 px-10 rounded-xl shadow-lg transition-all duration-200 text-xl w-44 h-48"
          >
            <FaPills className="text-5xl mb-4" /> {/* Ingrandisco l'icona */}
            Integrazioni
          </button>
          {/* Bottone per Edit Nutrition */}
          <button
            onClick={goToEditNutrition}
            className="flex flex-col items-center justify-center bg-green-600 hover:bg-green-700 text-white font-semibold py-6 px-10 rounded-xl shadow-lg transition-all duration-200 text-xl w-44 h-48"
          >
            <FaApple className="text-5xl mb-4" /> {/* Ingrandisco l'icona */}
            Nutrizione
          </button>
        </div>
        <div className="flex justify-center">
          <button
            onClick={closeModal}
            className="flex mt-6 text-lg w-full justify-center text-white bg-red-500 items-center border border-red-500 rounded-xl p-2"
          >
            Chiudi
          </button>
        </div>
      </div>
    </div>
  );
};
export default MiddleModalNutrition;