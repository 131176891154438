import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebase"; // Importa la tua configurazione di Firebase
import { doc, getDoc } from "firebase/firestore"; // Importa i metodi di Firestore
import { useParams } from "react-router-dom";
import { FaTrash } from "react-icons/fa";

const EditPasti = ({ selectedWeek, pastiOn, setPastiOn, pastiOff, setPastiOff }) => {
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchPasti = async () => {
            setLoading(true); // Imposta loading a true prima di recuperare i dati
            console.log("Recupero pasti per la settimana:", selectedWeek); // Log della settimana selezionata
            try {
                const dietDocRef = doc(db, "dieta", id); // Riferimento al documento
                const docSnap = await getDoc(dietDocRef); // Recupera il documento
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    console.log("Dati recuperati dal DB:", data); // Log dei dati recuperati
                    // Imposta i pastiOn e pastiOff in base ai dati recuperati
                    setPastiOn(data[selectedWeek]?.pastiOn || []);
                    setPastiOff(data[selectedWeek]?.pastiOff || []);
                } else {
                    console.error("Nessun documento trovato!");
                }
            } catch (error) {
                console.error("Errore nel recuperare i dati dei pasti: ", error);
            } finally {
                setLoading(false); // Imposta loading a false una volta completato il recupero
            }
        };

        fetchPasti(); // Chiama la funzione di recupero
    }, [selectedWeek, id, setPastiOn, setPastiOff]); // Dipendenze per useEffect

    // Funzioni per la gestione dei pasti
    const addPastoOn = (e) => {
        e.preventDefault();
        const newPasto = { alimenti: [] }; // Nuovo pasto con solo array di alimenti
        setPastiOn((prev) => [...prev, newPasto]);
    };

    const addPastoOff = (e) => {
        e.preventDefault();
        const newPasto = { alimenti: [] };
        setPastiOff((prev) => [...prev, newPasto]);
    };

    const removePastoOn = (index) => {
        setPastiOn((prev) => prev.filter((_, i) => i !== index));
    };

    const removePastoOff = (index) => {
        setPastiOff((prev) => prev.filter((_, i) => i !== index));
    };

    // Funzioni per la gestione degli alimenti
    const addAlimento = (event, index, type) => {
        event.preventDefault(); // Impedisce il comportamento predefinito dell'evento
        const newAlimento = { nome: "", quantita: "" }; // Nuovo oggetto alimento
        const newPasti = type === "on" ? [...pastiOn] : [...pastiOff]; // Determina quale array aggiornare
        newPasti[index].alimenti = [...newPasti[index].alimenti, newAlimento]; // Aggiungi l'alimento al pasto
        type === "on" ? setPastiOn(newPasti) : setPastiOff(newPasti);
    };
    const removeAlimento = (pastoIndex, alimentoIndex, type) => {
        const newPasti = type === "on" ? [...pastiOn] : [...pastiOff];
        newPasti[pastoIndex].alimenti = newPasti[pastoIndex].alimenti.filter((_, i) => i !== alimentoIndex);
        type === "on" ? setPastiOn(newPasti) : setPastiOff(newPasti);
    };

    const handleAlimentoChange = (e, pastoIndex, alimentoIndex, type) => {
        const { name, value } = e.target;
        const newPasti = type === "on" ? [...pastiOn] : [...pastiOff];
        newPasti[pastoIndex].alimenti[alimentoIndex] = {
            ...newPasti[pastoIndex].alimenti[alimentoIndex],
            [name]: value,
        };
        type === "on" ? setPastiOn(newPasti) : setPastiOff(newPasti);
    };

    if (loading) return <div>Loading...</div>; // Stato di caricamento

    return (
        <div className="flex justify-between items-start border-t-2 border-b-2 py-4 space-x-4 mt-16">
            {/* Pasti Giorni ON */}
            <div className="flex-1 w-96 p-4 border border-gray-300 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4 text-center">Pasti Giorni ON</h2>
                {pastiOn.length === 0 && <p className="text-center">Nessun pasto aggiunto</p>}
                {pastiOn.map((pasto, index) => (
                    <div key={index}>
                        <div className="flex  items-center mb-2 mt-4">
                            
                            <button
                                onClick={() => removePastoOn(index)}
                                className="bg-red-500 text-white px-4 py-2 rounded"
                            >
                                <FaTrash/>
                            </button>
                            <label className="ml-4 text-2xl">{`Pasto ${index + 1}`}</label>
                        </div>

                        {/* Sezione Alimenti */}
                        <div className="ml-8">
                            <h3 className="text-xl font-semibold mb-2">Alimenti</h3>
                            {pasto.alimenti.length === 0 && <p>Nessun alimento aggiunto</p>}
                            {pasto.alimenti.map((alimento, alimentoIndex) => (
                                <div key={alimentoIndex} className="flex mb-2">
                                    <input
                                        type="text"
                                        name="nome"
                                        placeholder="Nome alimento"
                                        value={alimento.nome}
                                        onChange={(e) => handleAlimentoChange(e, index, alimentoIndex, "on")}
                                        className="border p-2 rounded mr-2"
                                    />
                                    <input
                                        type="text"
                                        name="quantita"
                                        placeholder="Quantità"
                                        value={alimento.quantita}
                                        onChange={(e) => handleAlimentoChange(e, index, alimentoIndex, "on")}
                                        className="border p-2 rounded mr-2"
                                    />
                                    <button
                                        onClick={() => removeAlimento(index, alimentoIndex, "on")}
                                        className="bg-red-500 text-white px-2 rounded"
                                    >
                                        Rimuovi Alimento
                                    </button>
                                </div>
                            ))}
                            <button
                               onClick={(event) => addAlimento(event, index, "on")}
                                className="bg-green-500 text-white px-4 py-2 rounded mt-2"
                            >
                                Aggiungi Alimento
                            </button>
                        </div>
                    </div>
                ))}
                <button
                    onClick={(e) => addPastoOn(e)}
                    className="bg-blue-500 text-white px-4 py-2 rounded mb-4 block mx-auto mt-4"
                >
                    Aggiungi Pasto ON
                </button>
            </div>

            {/* Separator */}
            <div className="border-l-2 border-gray-400 mx-4"></div>

            {/* Pasti Giorni OFF */}
            <div className="flex-1 w-96 p-4 border border-gray-300 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4 text-center">Pasti Giorni OFF</h2>
                {pastiOff.length === 0 && <p className="text-center">Nessun pasto aggiunto</p>}
                {pastiOff.map((pasto, index) => (
                    <div key={index} className="mt-4">
                        <div className="flex  items-center mb-2">
                            <button
                                onClick={() => removePastoOff(index)}
                                className="bg-red-500 text-white px-4 py-2 rounded"
                            >
                                <FaTrash/>
                            </button>
                            <label className="ml-4 text-2xl">{`Pasto ${index + 1}`}</label>
                        </div>

                        {/* Sezione Alimenti */}
                        <div className="ml-8">
                            <h3 className="text-xl font-semibold mb-2">Alimenti</h3>
                            {pasto.alimenti.length === 0 && <p>Nessun alimento aggiunto</p>}
                            {pasto.alimenti.map((alimento, alimentoIndex) => (
                                <div key={alimentoIndex} className="flex mb-2">
                                    <input
                                        type="text"
                                        name="nome"
                                        placeholder="Nome alimento"
                                        value={alimento.nome}
                                        onChange={(e) => handleAlimentoChange(e, index, alimentoIndex, "off")}
                                        className="border p-2 rounded mr-2"
                                    />
                                    <input
                                        type="text"
                                        name="quantita"
                                        placeholder="Quantità"
                                        value={alimento.quantita}
                                        onChange={(e) => handleAlimentoChange(e, index, alimentoIndex, "off")}
                                        className="border p-2 rounded mr-2"
                                    />
                                    <button
                                        onClick={() => removeAlimento(index, alimentoIndex, "off")}
                                        className="bg-red-500 text-white px-2 rounded"
                                    >
                                        Rimuovi Alimento
                                    </button>
                                </div>
                            ))}
                            <button
                                  onClick={(event) => addAlimento(event, index, "off")}
                                className="bg-green-500 text-white px-4 py-2 rounded mt-2"
                            >
                                Aggiungi Alimento
                            </button>
                        </div>
                    </div>
                ))}
                <button
                    onClick={(e) => addPastoOff(e)}
                    className="bg-blue-500 text-white px-4 py-2 rounded mb-4 block mx-auto mt-4"
                >
                    Aggiungi Pasto OFF
                </button>
            </div>
        </div>
    );
};

export default EditPasti;
