import React, { useState, useEffect } from "react";
import {
  FaDumbbell,
  FaRunning,
  FaBiking,
  FaSwimmingPool,
  FaTrash,
  FaPlus,
  FaPenNib,
  FaArrowLeft,
  FaSearch,
} from "react-icons/fa";
import Modal from "./Modal";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

import { db } from "../../firebase/firebase";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../header/index"; // Assicurati che il percorso sia corretto
import { useNavigate } from "react-router-dom";
import WeeklyAdvice from "../WeeklyAdvice";
import Resoconto from "./Resoconto"; // Assicurati di importare il componente Resoconto

const defaultExercises = {
  chest: [
    { name: "Bench Press", icon: <FaDumbbell /> },
    { name: "Chest Fly", icon: <FaDumbbell /> },
  ],
  triceps: [
    { name: "Tricep Dips", icon: <FaDumbbell /> },
    { name: "Tricep Extensions", icon: <FaDumbbell /> },
  ],
  biceps: [
    { name: "Bicep Curls", icon: <FaDumbbell /> },
    { name: "Hammer Curls", icon: <FaDumbbell /> },
  ],
  back: [
    { name: "Pull-Ups", icon: <FaDumbbell /> },
    { name: "Deadlift", icon: <FaDumbbell /> },
  ],
  shoulders: [
    { name: "Shoulder Press", icon: <FaDumbbell /> },
    { name: "Lateral Raises", icon: <FaDumbbell /> },
  ],
  legs: [
    { name: "Squat", icon: <FaDumbbell /> },
    { name: "Lunges", icon: <FaDumbbell /> },
  ],
  cardio: [
    { name: "Running", icon: <FaRunning />, category: "cardio" },
    { name: "Cycling", icon: <FaBiking />, category: "cardio" },
    { name: "Swimming", icon: <FaSwimmingPool />, category: "cardio" },
  ],
};

const EditExercise = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentExercise, setCurrentExercise] = useState(null);
  const [exerciseDetails, setExerciseDetails] = useState({});
  const [giorni, setGiorni] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [newExercise, setNewExercise] = useState({ name: "", category: "" });
  const [exercises, setExercises] = useState(defaultExercises);
  const [userName, setUserName] = useState(""); // Stato per il nome dell'utente
  const [dayExercises, setDayExercises] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [activeCategories, setActiveCategories] = useState([]);
  const [adviceList, setAdviceList] = useState([]); // Stato con un array di consigli
  const [adviceNumber, setAdviceNumber] = useState(1); // Stato per gestire i numeri del consiglio

  // Funzione per aggiungere un nuovo input
  const addAdvice = () => {
    setAdviceList([...adviceList, ""]);
    setAdviceNumber(adviceNumber + 2); // Incrementiamo il numero del consiglio di 2
  };

  // Rimuovi un consiglio dalla lista
  const removeAdvice = (index) => {
    const updatedAdviceList = adviceList.filter((_, i) => i !== index); // Filtra e rimuove il consiglio
    setAdviceList(updatedAdviceList);
    setAdviceNumber(adviceNumber - 2); // Riduciamo di 2 il numero del consiglio
  };

  const handleAdviceChange = (index, newAdvice) => {
    const updatedAdviceList = [...adviceList];
    updatedAdviceList[index] = newAdvice;
    setAdviceList(updatedAdviceList);
  };

  useEffect(() => {
    const fetchWorkoutDays = async () => {
      try {
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setGiorni(data.giorni || []);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching workout days:", error);
      }
    };

    fetchWorkoutDays();
  }, [id]);

  const fetchExercisesFromFirestore = async () => {
    try {
      const exercisesRef = doc(db, "esercizi", "allExercises"); // Puntando al documento unico
      const exercisesSnap = await getDoc(exercisesRef); // Usa getDoc per un singolo documento

      if (exercisesSnap.exists()) {
        const fetchedExercises = exercisesSnap.data(); // Ottieni i dati del documento

        // Crea un oggetto per gli esercizi recuperati
        const combinedExercises = { ...defaultExercises };

        // Aggiungi gli esercizi recuperati agli esercizi combinati
        for (const category in fetchedExercises) {
          // Verifica se la categoria esiste
          if (fetchedExercises[category]) {
            combinedExercises[category] = [
              ...(combinedExercises[category] || []),
              ...fetchedExercises[category].map((exercise) => ({
                name: exercise.name,
                icon: <FaDumbbell />, // Imposta un'icona predefinita
              })),
            ];
          }
        }

        setExercises(combinedExercises); // Imposta il nuovo stato
      } else {
        console.log("Nessun esercizio trovato nel database.");
        setExercises(defaultExercises); // In caso di assenza di esercizi, usa quelli di default
      }
    } catch (error) {
      console.error("Errore nel recupero degli esercizi:", error);
    }
  };

  //recupero esercizi
  useEffect(() => {
    const fetchWorkoutData = async () => {
      try {
        const docRef = doc(db, "schedeAllenamento", userName);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setDayExercises(data.exercises || {});
        } else {
          console.log("Nessuna scheda trovata per l'utente.");
        }
      } catch (error) {
        console.error("Errore nel recuperare gli esercizi:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userName) {
      fetchWorkoutData();
    }
  }, [userName]);

  const handleExerciseClick = (exercise) => {
    if (!selectedDay) {
      Swal.fire({
        title: "Seleziona un giorno",
        text: "Devi selezionare un giorno prima di associare un esercizio.",
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "green",
      });
      return;
    }

    setCurrentExercise(exercise);
    setIsModalVisible(true);
  };

  const handleDetailChange = (field, value) => {
    setExerciseDetails((prevDetails) => ({
      ...prevDetails,
      [currentExercise.name]: {
        ...prevDetails[currentExercise.name],
        [field]: value,
      },
    }));
  };
  const handleModalSave = () => {
    if (currentExercise && selectedDay) {
      // Otteniamo gli esercizi del giorno selezionato
      setDayExercises((prevDayExercises) => {
        const exercisesForDay = prevDayExercises[selectedDay] || [];

        // Trova l'indice dell'esercizio che stiamo modificando
        const exerciseIndex = exercisesForDay.findIndex(
          (exercise) => exercise.name === currentExercise.name
        );

        if (exerciseIndex !== -1) {
          // Se l'esercizio esiste nel giorno selezionato, lo aggiorniamo
          const updatedExercises = exercisesForDay.map((exercise, index) =>
            index === exerciseIndex
              ? { ...exercise, ...exerciseDetails[currentExercise.name] }
              : exercise
          );

          return {
            ...prevDayExercises,
            [selectedDay]: updatedExercises, // Aggiorna solo il giorno selezionato
          };
        } else {
          // Se l'esercizio non esiste nel giorno selezionato, lo aggiungiamo come nuovo
          return {
            ...prevDayExercises,
            [selectedDay]: [
              ...exercisesForDay,
              { ...currentExercise, ...exerciseDetails[currentExercise.name] },
            ],
          };
        }
      });

      // Reset dei dettagli dell'esercizio dopo il salvataggio
      setExerciseDetails({
        ...exerciseDetails,
        [currentExercise.name]: {
          duration: "",
          series: "",
          reps: "",
          note: "",
        },
      });
    }

    // Chiudiamo il modal e resettiamo l'esercizio corrente
    setIsModalVisible(false);
    setCurrentExercise(null);
  };

  const handleGoHome = () => {
    navigate("/home"); // Naviga alla home page
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
    setCurrentExercise(null);
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  // Funzione per aprire il modal di aggiunta nuovo esercizio
  const handleAddExerciseClick = (category) => {
    setNewExercise({ ...newExercise, category });
    setIsAddModalVisible(true);
  };

  // Gestire l'inserimento del nuovo esercizio
  const handleAddExerciseSave = async () => {
    if (newExercise.name && newExercise.category) {
      try {
        // Crea una copia immutabile dello stato 'exercises'
        setExercises((prevExercises) => ({
          ...prevExercises,
          [newExercise.category]: [
            ...(prevExercises[newExercise.category] || []),
            { name: newExercise.name, icon: <FaDumbbell /> },
          ],
        }));

        // Riferimento al documento nel Firestore (assicurati di usare un ID fisso o specifico per questo documento)
        const exercisesRef = doc(db, "esercizi", "allExercises"); // Usa un ID fisso come "allExercises"

        // Controlla se il documento esiste
        const docSnap = await getDoc(exercisesRef);
        if (!docSnap.exists()) {
          // Se non esiste, crea un nuovo documento con array vuoti
          await setDoc(exercisesRef, {
            legs: [],
            shoulders: [],
            back: [],
            chest: [],
            biceps: [],
            triceps: [],
          });
        }

        // Aggiungi l'esercizio all'array corretto nel documento esistente
        await updateDoc(exercisesRef, {
          [newExercise.category]: arrayUnion({
            name: newExercise.name,
            icon: "<FaDumbbell />", // Puoi omettere l'icona se non è necessaria in Firestore
          }),
        });

        // Aggiorna gli esercizi nei giorni di allenamento se il giorno è selezionato
        if (selectedDay) {
          setDayExercises((prevDayExercises) => ({
            ...prevDayExercises,
            [selectedDay]: [
              ...(prevDayExercises[selectedDay] || []),
              { name: newExercise.name, category: newExercise.category },
            ],
          }));
        }

        setNewExercise({ name: "", category: "" });
        setIsAddModalVisible(false);
        Swal.fire({
          title: "Esercizio aggiunto con successo!",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "green",
        });
      } catch (error) {
        console.error("Errore durante il salvataggio dell'esercizio:", error);
        Swal.fire({
          title: "Errore!",
          text: "C'è stato un errore durante il salvataggio dell'esercizio.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "red",
        });
      }
    }
  };

  const handleAddExerciseClose = () => {
    setIsAddModalVisible(false);
    setNewExercise({ name: "", category: "" });
  };

  //modifica inline diciamo
  const handleModify = (day, index) => {
    // Imposta l'esercizio corrente e il giorno corrente per la modifica
    setCurrentExercise(dayExercises[day][index]); // L'esercizio nel giorno specifico
    setSelectedDay(day); // Imposta il giorno selezionato per la modifica
    setExerciseDetails((prevDetails) => ({
      ...prevDetails,
      [dayExercises[day][index].name]: {
        // Imposta i dettagli dell'esercizio corrente
        duration: dayExercises[day][index].duration || "",
        series: dayExercises[day][index].series || "",
        reps: dayExercises[day][index].reps || "",
        note: dayExercises[day][index].note || "",
      },
    }));
    setIsModalVisible(true); // Mostra il modal per la modifica
  };

  // Eliminazione di un esercizio già scelto
  const handleDelete = (day, exerciseIndex) => {
    Swal.fire({
      title: "Sei sicuro di voler eliminare questo esercizio?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Elimina",
      denyButtonText: "Annulla",
      confirmButtonColor: "red",
      denyButtonColor: "green",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Copia la lista degli esercizi del giorno e rimuovi l'esercizio selezionato
          const updatedExercises = [...dayExercises[day]];
          updatedExercises.splice(exerciseIndex, 1);

          // Aggiorna lo stato con la lista aggiornata
          setDayExercises((prevDayExercises) => ({
            ...prevDayExercises,
            [day]: updatedExercises,
          }));

          Swal.fire({
            title: "Eliminazione avvenuta con successo!",
            icon: "success",
            showConfirmButton: true, // Mostra un bottone "OK"
            confirmButtonText: "OK",
            confirmButtonColor: "green",
          });
        } catch (error) {
          Swal.fire(
            "Errore durante l'eliminazione dell'esercizio",
            "",
            "error"
          );
          console.error("Error deleting exercise: ", error);
        }
      } else if (result.isDenied) {
        Swal.fire("Operazione annullata", "", "info");
      }
    });
  };

  const deleteExercise = () => {
    Swal.fire({
      title: "Inserisci il nome dell'esercizio che vuoi eliminare",
      input: "text",
      inputPlaceholder: "Nome esercizio",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Elimina",
      denyButtonText: "Annulla",
      confirmButtonColor: "red",
      denyButtonColor: "green",
    }).then((result) => {
      if (result.isConfirmed) {
        const exerciseName = result.value;
        let exerciseFound = false;

        // Create a copy of the exercises state
        const updatedExercises = { ...exercises };

        // Search and remove the exercise in the copy
        for (const category in updatedExercises) {
          const exerciseIndex = updatedExercises[category].findIndex(
            (exercise) =>
              exercise.name.toLowerCase() === exerciseName.toLowerCase()
          );

          if (exerciseIndex !== -1) {
            updatedExercises[category].splice(exerciseIndex, 1);
            exerciseFound = true;
            break;
          }
        }

        // Update the state with the modified copy
        setExercises(updatedExercises);

        if (exerciseFound) {
          Swal.fire({
            title: "Esercizio eliminato con successo!",
            icon: "success",
            showConfirmButton: true, // Mostra un bottone "OK"
            confirmButtonText: "OK",
            confirmButtonColor: "green",
          });
        } else {
          Swal.fire("Esercizio non trovato", "", "error");
        }
      } else if (result.isDenied) {
        Swal.fire({
          title: "Operazione annullata!",
          icon: "info",
          showConfirmButton: true, // Mostra un bottone "OK"
          confirmButtonText: "OK",
          confirmButtonColor: "green",
        });
      }
    });
  };
  //fetcha il nome dal DB e prepara la stringa da inserire
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, "users", id); // Ottieni il documento dell'utente
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUserName(userData.nome + " " + userData.cognome); // Supponendo che `nome` sia un campo nel documento
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
    fetchExercisesFromFirestore(); // Recupera gli esercizi esistenti
  }, [id]);

  const handleSendToFirestore = async () => {
    try {
      const organizedExercises = {};

      // Filtra i giorni che hanno esercizi validi
      for (const [day, exercises] of Object.entries(dayExercises)) {
        const filteredExercises = exercises
          .filter(
            (exercise) =>
              exercise.name &&
              (exercise.duration || (exercise.series && exercise.reps))
          )
          .map((exercise) => {
            const exerciseData = {
              name: exercise.name,
            };
            if (exercise.duration) {
              exerciseData.duration = exercise.duration;
            }
            if (exercise.series && exercise.reps) {
              exerciseData.series = exercise.series;
              exerciseData.reps = exercise.reps;
            }
            if (exercise.note) {
              exerciseData.note = exercise.note;
            }
            return exerciseData;
          });

        // Aggiungi il giorno solo se ha esercizi validi
        if (filteredExercises.length > 0) {
          organizedExercises[day] = filteredExercises;
        }
      }

      // Verifica se ci sono esercizi da salvare
      if (Object.keys(organizedExercises).length === 0) {
        Swal.fire("Nessun esercizio valido da salvare.", "", "warning");
        return;
      }

      // Crea un riferimento al documento Firestore
      const docRef = doc(db, "schedeAllenamento", userName);

      console.log(adviceList);
      // Usa setDoc per salvare i dati con l'ID specificato
      await setDoc(docRef, {
        exercises: organizedExercises,
        Consigli: adviceList,
      });

      await Swal.fire({
        title: "Scheda inviata con successo a: </br>" + userName,
        text: "I tuoi esercizi sono stati inviati al database.",
        icon: "success",
        confirmButtonText: "CONFERMA INVIO",
        confirmButtonColor: "green",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/home");
        }
      });
      console.log("Documento scritto con ID: ", docRef.id);
    } catch (e) {
      Swal.fire({
        title: "Errore!",
        text: "C'è stato un errore durante il salvataggio degli esercizi. Per favore riprova.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "red",
      });
      console.error("Errore aggiungendo il documento: ", e);
    }
  };
  const updateFirestore = async () => {
    try {
      const docRef = doc(db, "schedeAllenamento", userName);
      await setDoc(docRef, { exercises: dayExercises });
    } catch (error) {
      console.error("Errore durante l'aggiornamento di Firestore: ", error);
    }
  };

  useEffect(() => {
    if (userName) {
      updateFirestore();
    }
  }, [dayExercises]);

  const onChangeSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleCategory = (category) => {
    setActiveCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((cat) => cat !== category)
        : [...prevCategories, category]
    );
  };

  return (
    <div className="container mx-auto px-4 py-16">
      <Header isVisible={true} /> {/* Header sempre visibile */}
      <div className="flex flex-col items-center ">
        <div className="flex items-center">
          <button
            onClick={() => navigate("/home")}
            className="flex items-center text-blue-500 hover:text-blue-700  md:hidden " // Mostra solo su schermi 'md' e più grandi
          >
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-4 mt-4 ">
            Modifica Esercizi
          </h1>
        </div>
        <div className="mb-8">
          <div className="flex flex-wrap gap-2">
            {giorni > 0 ? (
              Array.from({ length: giorni }, (_, index) => (
                <div
                  key={index}
                  className={`px-4 py-2 rounded-lg cursor-pointer ${
                    selectedDay === index + 1 ? "bg-blue-500" : "bg-blue-200"
                  }`}
                  onClick={() => handleDayClick(index + 1)}
                >
                  Giorno {index + 1}
                </div>
              ))
            ) : (
              <p className="text-gray-600">
                Nessun giorno di allenamento selezionato.
              </p>
            )}
          </div>
        </div>
      </div>
      {/* Search Bar */}
      <div className="flex justify-center mt-10">
        <div className="mb-4 flex items-center border border-gray-300 rounded-lg p-2 w-full sm:w-80 md:w-1/2 focus-within:ring-2 focus-within:ring-blue-500">
          <FaSearch className="text-gray-500 mr-2" />
          <input
            className="outline-none w-full"
            type="search"
            placeholder="Cerca un esercizio..."
            title="Cerca..."
            onChange={onChangeSearch}
          />
        </div>
      </div>
      {/* Categories */}
      <div className="flex flex-wrap justify-center mb-10">
        {Object.keys(exercises).map((category) => (
          <span
            key={category}
            onClick={() => toggleCategory(category)}
            className={`inline-block cursor-pointer text-white text-xs px-4 py-2 mx-1 my-1 rounded-full select-none ${
              activeCategories.includes(category)
                ? "bg-blue-500"
                : "bg-gray-400"
            } ${
              activeCategories.includes(category)
                ? "hover:bg-blue-600"
                : "hover:bg-gray-500"
            }`}
          >
            {category.toLowerCase()}
          </span>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {Object.keys(exercises)
          .filter(
            (category) =>
              activeCategories.length === 0 ||
              activeCategories.includes(category)
          )
          .map((category) => (
            <div key={category} className="mb-8">
              <div className="flex items-center">
                <h2 className="text-xl font-bold mb-4">
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </h2>
                <button
                  onClick={() => handleAddExerciseClick(category)}
                  className="mb-3 ml-2 text-green-500 hover:text-green-700 focus:outline-none"
                >
                  <FaPlus size={18} />
                </button>
                <button
                  onClick={() => deleteExercise()}
                  className="mb-3 ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                >
                  <FaTrash size={18} />
                </button>
              </div>
              <div className="flex flex-wrap gap-4">
                {exercises[category]
                  .filter((exercise) =>
                    exercise.name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  .map((exercise) => (
                    <div
                      key={exercise.name}
                      className="flex items-center p-4 border rounded-lg relative cursor-pointer hover:bg-gray-200"
                      onClick={() => handleExerciseClick(exercise)}
                    >
                      {exercise.icon}
                      <span className="ml-2">{exercise.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
      <div className="p-4">
        <h1 className="font-bold text-2xl mb-4">Consigli settimanali</h1>

        {/* Bottone per aggiungere un nuovo consiglio */}
        <div className="inline-flex mb-3 -ml-2 text-green-500 hover:text-green-700 focus:outline-none border border-green-500 rounded-xl p-4">
          <button
            onClick={addAdvice}
            className="flex items-center gap-2 text-green-500 font-medium text-xl"
          >
            <FaPlus />
            Aggiungi un nuovo consiglio
          </button>
        </div>

        {/* Mappa attraverso i consigli e renderizzali */}
        {adviceList.map((advice, index) => (
          <div key={index} className="relative mb-4">
            <button
              onClick={() => removeAdvice(index)} // Funzione per rimuovere il consiglio
              className="mt-2 -ml-2 mr-2 text-red-500 hover:text-red-700"
            >
              <FaTrash />
            </button>
            <WeeklyAdvice
              advice={advice}
              numberAdvice={`${
                adviceNumber - (adviceList.length - index) * 2
              }-${adviceNumber - (adviceList.length - index) * 2 + 1}`} // Numero dinamico
              onAdviceChange={(newAdvice) =>
                handleAdviceChange(index, newAdvice)
              }
            />
          </div>
        ))}
      </div>
      <div className="mt-8">
        <Resoconto
          dayExercises={dayExercises}
          setDayExercises={setDayExercises}
          handleModify={handleModify}
          handleDelete={handleDelete}
        />
      </div>
      <div className="flex justify-center items-center gap-5 mt-10">
        <button
          type="button"
          className="bg-green-500 text-white py-2 px-4 rounded"
          onClick={handleSendToFirestore}
        >
          Salva Esercizi
        </button>
        <button
          onClick={handleGoHome}
          className=" px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded focus:outline-none"
        >
          Torna alla Home
        </button>
      </div>
      {currentExercise && (
        <Modal isVisible={isModalVisible} onClose={handleModalClose}>
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">{currentExercise.name}</h2>
            {currentExercise.category === "cardio" ? (
              <div className="mb-2">
                <label className="block text-gray-700 text-sm font-bold mb-1">
                  Durata (minuti):
                </label>
                <input
                  type="text"
                  value={exerciseDetails[currentExercise.name]?.duration || ""}
                  onChange={(e) =>
                    handleDetailChange("duration", e.target.value)
                  }
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            ) : (
              <>
                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-1">
                    Numero di Serie:
                  </label>
                  <input
                    type="text"
                    value={exerciseDetails[currentExercise.name]?.series || ""}
                    onChange={(e) =>
                      handleDetailChange("series", e.target.value)
                    }
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-1">
                    Numero di Ripetizioni:
                  </label>
                  <input
                    type="text"
                    value={exerciseDetails[currentExercise.name]?.reps || ""}
                    onChange={(e) => handleDetailChange("reps", e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-1">
                    Note per l'esercizio:
                  </label>
                  <textarea
                    style={{ height: 120 }}
                    value={exerciseDetails[currentExercise.name]?.note || ""}
                    onChange={(e) => handleDetailChange("note", e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </>
            )}
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleModalSave}
                className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Salva
              </button>
              <button
                onClick={handleModalClose}
                className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Annulla
              </button>
            </div>
          </div>
        </Modal>
      )}
      {currentExercise && (
        <Modal isVisible={isModalVisible} onClose={handleModalClose}>
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">{currentExercise.name}</h2>
            {currentExercise.category === "cardio" ? (
              <div className="mb-2">
                <label className="block text-gray-700 text-sm font-bold mb-1">
                  Durata (minuti):
                </label>
                <input
                  type="text"
                  value={exerciseDetails[currentExercise.name]?.duration || ""}
                  onChange={(e) =>
                    handleDetailChange("duration", e.target.value)
                  }
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            ) : (
              <>
                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-1">
                    Numero di Serie:
                  </label>
                  <input
                    type="text"
                    value={exerciseDetails[currentExercise.name]?.series || ""}
                    onChange={(e) =>
                      handleDetailChange("series", e.target.value)
                    }
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-1">
                    Numero di Ripetizioni:
                  </label>
                  <input
                    type="text"
                    value={exerciseDetails[currentExercise.name]?.reps || ""}
                    onChange={(e) => handleDetailChange("reps", e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-1">
                    Note per l'esercizio:
                  </label>
                  <textarea
                    style={{ height: 120 }}
                    value={exerciseDetails[currentExercise.name]?.note || ""}
                    onChange={(e) => handleDetailChange("note", e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </>
            )}
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleModalSave}
                className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Salva
              </button>
              <button
                onClick={handleModalClose}
                className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Annulla
              </button>
            </div>
          </div>
        </Modal>
      )}
      {/* Modal per aggiungere un nuovo esercizio alla categoria*/}
      {isAddModalVisible && (
        <Modal isVisible={isAddModalVisible} onClose={handleAddExerciseClose}>
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Aggiungi Nuovo Esercizio</h2>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Nome Esercizio:
              </label>
              <input
                type="text"
                value={newExercise.name}
                onChange={(e) => {
                  const value = e.target.value;
                  // Converte la prima lettera in maiuscola
                  const capitalizedValue =
                    value.charAt(0).toUpperCase() + value.slice(1);
                  setNewExercise((prev) => ({
                    ...prev,
                    name: capitalizedValue,
                  }));
                }}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Categoria Esercizio:
              </label>
              <select
                value={newExercise.category}
                onChange={(e) =>
                  setNewExercise((prev) => ({
                    ...prev,
                    category: e.target.value,
                  }))
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Seleziona Categoria</option>
                {Object.keys(exercises).map((category) => (
                  <option key={category} value={category}>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleAddExerciseSave}
                className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Aggiungi
              </button>
              <button
                onClick={handleAddExerciseClose}
                className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Annulla
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EditExercise;
