import React from 'react';
import { FaCircle, FaCheckCircle, FaPlus, FaMinus, FaTrash } from 'react-icons/fa';

const ExerciseDetails = ({
  day,
  exercises,
  savedDate,
  handleDateChange,
  handleClearDate,
  completedExercises,
  handleAddSeries,
  handleRemoveSeries,
  handleRepsChange,
  dateError,
}) => {
  return (
    <div key={day} className="mt-2 mb-4 p-2 border p-4">
      <h5 className="font-semibold text-blue-600" style={{ fontSize: 22 }}>
        Giorno {day}
      </h5>
      <div className="mt-2 mb-2">
        <label className="labelTitle">
          Inserisci <strong>l'intero esercizio</strong>
        </label>
        <label className="labelTitle"> non una serie alla volta</label>
        <div className="flex items-center">
          <input
            type="date"
            value={savedDate || ""}
            onChange={(e) => handleDateChange(e.target.value, day)}
            placeholder="gg/mm/aaaa"
            className="mb-2 w-64 text-lg focus:outline-none"
          />
          <button
            type="button"
            onClick={() => handleClearDate(day)}
            className="ml-2 mb-2.5 text-red-500 hover:text-red-700"
          >
            <FaTrash size={18} />
          </button>
        </div>
      </div>
      {dateError && <p className="text-red-500">{dateError}</p>}
      
      <ul>
        <label className="labelTitle">Inserendo più di un esercizio alla volta </label>
        <label className="labelTitle">ti basta salvare il primo per salvare anche gli altri </label>
        
        {Array.isArray(exercises[day]) ? (
          exercises[day].map((exercise, index) => {
            const exerciseKey = `${day}-${index}`;
            const isCompleted = completedExercises[exerciseKey];

            return (
              <div key={index} className="mb-2">
                <li
                  className={`mb-2 cursor-pointer flex justify-between items-center rounded-lg ${isCompleted ? "bg-green-200 pr-1" : ""}`}
                >
                  <div className="flex items-center w-full">
                    <div className="mr-2">
                      {isCompleted ? (
                        <FaCheckCircle className="text-green-500" size={22} />
                      ) : (
                        <FaCircle className="text-amber-500" />
                      )}
                    </div>

                    <span className={`text-black flex-grow ${isCompleted ? "text-green-800 font-bold" : ""}`} style={{ fontSize: 18 }}>
                      <strong>{exercise.name}</strong>
                      {exercise.series
                        ? ` ${exercise.series} x ${exercise.reps}`
                        : exercise.duration
                        ? ` ${exercise.duration}`
                        : ""}
                    </span>

                    <div className="flex items-center ml-2">
                      <button onClick={() => handleAddSeries(day, index)} className="text-blue-500">
                        <FaPlus size={22} />
                      </button>
                      {exercise.seriesList?.length > 0 && (
                        <button onClick={() => handleRemoveSeries(day, index)} className="text-red-500 ml-1">
                          <FaMinus size={22} />
                        </button>
                      )}
                    </div>
                  </div>
                </li>

                {exercise.seriesList?.map((series, seriesIndex) => (
                  <div key={seriesIndex} className="series-item mt-2">
                    <div className="flex space-x-4 items-center">
                      <div className="flex flex-col w-full max-w-xs">
                        <span className="font-bold">Serie {series.series}:</span>
                        <input
                          type="text"
                          value={series.reps}
                          onChange={(e) =>
                            handleRepsChange(day, index, seriesIndex, e.target.value, "reps")
                          }
                          placeholder="Ripetizioni"
                          className="focus:outline-none focus:border-blue-500 px-2 py-1 border rounded-md w-full"
                        />
                      </div>
                      <div className="flex flex-col w-full max-w-xs">
                        <span className="font-bold">Kg:</span>
                        <input
                          type="number"
                          value={series.kg}
                          onChange={(e) =>
                            handleRepsChange(day, index, seriesIndex, e.target.value, "kg")
                          }
                          placeholder="Kg"
                          className="focus:outline-none focus:border-blue-500 px-2 py-1 border rounded-md w-full"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          })
        ) : (
          <li className="mb-2">Dati degli esercizi non disponibili</li>
        )}
      </ul>
    </div>
  );
};

export default ExerciseDetails;
