import React, { useState } from "react";
import Swal from "sweetalert2"; // Importa SweetAlert2

const Domande = ({ onNext, onBack }) => {
  // Stato per le domande già presenti
  const [infortunio, setInfortunio] = useState("");
  const [dieta, setDieta] = useState("");
  const [problematicheAlimentari, setProblematicheAlimentari] = useState("");
  const [altroInfortuni, setAltroInfortuni] = useState("");
  const [altroDieta, setAltroDieta] = useState("");
  const [altroProblematiche, setAltroProblematiche] = useState("");

  // Stato per le nuove domande con radio button
  const [additionalAnswers, setAdditionalAnswers] = useState({
    sendetario: "", // Sendentario/attivo/molto attivo
    obiettivoBreve: "", // Obiettivo a breve termine
    obiettivoLungo: "", // Obiettivo a lungo termine
    patologie: "", // Patologie/disturbi alimentari/intolleranze
    orarioAllenamento: "", // Orario di preferenza per allenarsi
    descrizioneCaratteriale: "", // Descriviti come persona dal punto di vista caratteriale
    sport: "", // Hai già fatto sport? Se sì, quali
    integratori: "", // Utilizzi integratori? Se sì, quali
    alimentiFastidio: "", // Ci sono alimenti che ti creano fastidio?
    alimentiPreferiti: "", // Alimenti di cui non puoi fare a meno
    zoneGrasso: "", // Zone dove tendi ad accumulare grasso
    zoneDimagrimento: "", // Zone dove dimagrisci maggiormente quando perdi grasso
    acquaGiornaliera: "", // Quanta acqua bevi al giorno
    ceneFuori: "", // Nel weekend quante volte vai a mangiare fuori
  });

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setAdditionalAnswers({ ...additionalAnswers, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verifica che tutti i campi obbligatori siano compilati
    const requiredFields = [
      infortunio,
      // dieta,
      // problematicheAlimentari,
      additionalAnswers.sendetario,
      additionalAnswers.obiettivoBreve,
      additionalAnswers.obiettivoLungo,
      additionalAnswers.patologie,
      /* additionalAnswers.orarioAllenamento,
            additionalAnswers.descrizioneCaratteriale,
            additionalAnswers.sport,
            additionalAnswers.integratori,
            additionalAnswers.alimentiFastidio,
            additionalAnswers.alimentiPreferiti,
            additionalAnswers.zoneGrasso,
            additionalAnswers.zoneDimagrimento,
            additionalAnswers.acquaGiornaliera,
            additionalAnswers.ceneFuori*/
    ];

    const allFieldsFilled = requiredFields.every(
      (field) => field.trim() !== ""
    );

    if (!allFieldsFilled) {
      Swal.fire({
        icon: "error",
        title: "Errore",
        text: "Per favore, compila tutti i campi richiesti.",
      });
      return;
    }

    // Combina le risposte delle domande nuove e precedenti
    const risposte = {
      infortunio: infortunio === "Altro" ? altroInfortuni : infortunio,
      dieta: dieta === "Altro" ? altroDieta : dieta,
      problematicheAlimentari:
        problematicheAlimentari === "Altro"
          ? altroProblematiche
          : problematicheAlimentari,
      ...additionalAnswers, // Aggiungi le nuove risposte
    };

    onNext({ risposte });
  };

  return (
    <div className="w-auto min-h-screen text-gray-600 p-4 shadow-xl border rounded-xl ">
      <form onSubmit={handleSubmit} className="mt-60 space-y-3">
        <div>
          <header className="w-full sticky top-0 z-50 bg-blue-600 shadow-lg py-6 px-4 rounded-b-lg mb-6 transition-all duration-300 ease-in-out hover:shadow-2xl">
            <div className="flex items-center justify-center text-center text-2xl font-semibold text-white">
              Domande aggiuntive
            </div>
          </header>
        </div>
        {/* Domande già esistenti */}
        <div className="">
          <div className="">
            <label className="text-sm text-gray-600 font-bold">
              Infortuni passati <span className="text-red-500">*</span>
            </label>
            <div className="flex space-x-4 mt-2">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="infortuni"
                  value="Altro"
                  checked={infortunio === "Altro"}
                  onChange={(e) => {
                    setInfortunio(e.target.value);
                    if (e.target.value !== "Altro") {
                      setAltroInfortuni("");
                    }
                  }}
                  className="form-radio text-blue-600"
                />
                <span>Descrivi...</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="infortuni"
                  value="No"
                  checked={infortunio === "No"}
                  onChange={(e) => setInfortunio(e.target.value)}
                  className="form-radio text-blue-600"
                />
                <span>No</span>
              </label>
            </div>
            <div
              className={`transition-all duration-300 ease-in-out overflow-hidden ${
                infortunio === "Altro" ? "max-h-40" : "max-h-0"
              }`}
            >
              <div className="mt-4">
                <input
                  type="text"
                  placeholder="Specificare"
                  value={altroInfortuni}
                  onChange={(e) => setAltroInfortuni(e.target.value)}
                  className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
                />
              </div>
            </div>
          </div>

          {/* Implementazione simile per la domanda successiva */}
          <div className="mt-4">
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Hai mai seguito una dieta? <span className="text-red-500">*</span>
            </label>
            <div className="flex space-x-4 mt-2">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="dieta"
                  value="Vegetariana"
                  checked={dieta === "Vegetariana"}
                  onChange={(e) => {
                    setDieta(e.target.value);
                    if (e.target.value !== "Altro") {
                      setAltroDieta("");
                    }
                  }}
                  className="form-radio text-blue-600"
                />
                <span>No</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="dieta"
                  value="Altro"
                  checked={dieta === "Altro"}
                  onChange={(e) => setDieta(e.target.value)}
                  className="form-radio text-blue-600"
                />
                <span>Altro</span>
              </label>
            </div>
            <div
              className={`transition-all duration-300 ease-in-out overflow-hidden ${
                dieta === "Altro" ? "max-h-40" : "max-h-0"
              }`}
            >
              <div className="mt-4">
                <input
                  type="text"
                  placeholder="Specificare"
                  value={altroDieta}
                  onChange={(e) => setAltroDieta(e.target.value)}
                  className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Problematiche alimentari <span className="text-red-500">*</span>
            </label>
            <div className="flex space-x-4 mt-3">
              <label className="flex items-center space-x-2 cursor-pointer hover:scale-105 transition-transform duration-300">
                <input
                  type="radio"
                  name="problematicheAlimentari"
                  value="No"
                  checked={problematicheAlimentari === "No"}
                  onChange={(e) => {
                    setProblematicheAlimentari(e.target.value);
                    if (e.target.value !== "Altro") {
                      setAltroProblematiche("");
                    }
                  }}
                  className="form-radio text-blue-600"
                />
                <span className="text-gray-800 font-medium">No</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer hover:scale-105 transition-transform duration-300">
                <input
                  type="radio"
                  name="problematicheAlimentari"
                  value="Altro"
                  checked={problematicheAlimentari === "Altro"}
                  onChange={(e) => setProblematicheAlimentari(e.target.value)}
                  className="form-radio text-blue-600"
                />
                <span className="text-gray-800 font-medium">Altro</span>
              </label>
            </div>

            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                problematicheAlimentari === "Altro"
                  ? "max-h-40 opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              <div className="mt-5">
                <input
                  type="text"
                  placeholder="Specificare"
                  value={altroProblematiche}
                  onChange={(e) => setAltroProblematiche(e.target.value)}
                  className="w-full px-4 py-2 text-gray-700 bg-white border-2 border-gray-200 focus:border-blue-600 shadow-md rounded-lg outline-none transition duration-300 focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50"
                />
              </div>
            </div>
          </div>

          {/* DOMANDE ATTIVITA' */}
          <div>
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Sei sendentario, attivo o molto attivo?
            </label>
            <div className="flex space-x-4 mt-2">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="sendetario"
                  value="Sendentario"
                  checked={additionalAnswers.sendetario === "Sendentario"}
                  onChange={handleRadioChange}
                  className="form-radio text-blue-600"
                />
                <span>Sendentario</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="sendetario"
                  value="Attivo"
                  checked={additionalAnswers.sendetario === "Attivo"}
                  onChange={handleRadioChange}
                  className="form-radio text-blue-600"
                />
                <span>Attivo</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="sendetario"
                  value="Molto attivo"
                  checked={additionalAnswers.sendetario === "Molto attivo"}
                  onChange={handleRadioChange}
                  className="form-radio text-blue-600"
                />
                <span>Molto attivo</span>
              </label>
            </div>
          </div>
          {/*DOMANDE BREVE TERMINE */}

          <div>
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Obiettivo a breve termine:
            </label>
            <div className="flex space-x-4 mt-2">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="obiettivoBreve"
                  value="Dimagrire"
                  checked={additionalAnswers.obiettivoBreve === "Dimagrire"}
                  onChange={handleRadioChange}
                  className="form-radio text-blue-600"
                />
                <span>Dimagrire</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="obiettivoBreve"
                  value="Mantenere"
                  checked={additionalAnswers.obiettivoBreve === "Mantenere"}
                  onChange={handleRadioChange}
                  className="form-radio text-blue-600"
                />
                <span>Mantenere</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="obiettivoBreve"
                  value="Aumentare massa muscolare"
                  checked={
                    additionalAnswers.obiettivoBreve ===
                    "Aumentare massa muscolare"
                  }
                  onChange={handleRadioChange}
                  className="form-radio text-blue-600"
                />
                <span>Aumentare</span>
              </label>
            </div>
          </div>
          <div>
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Obiettivo a lungo termine:
            </label>
            <div className="flex space-x-4 mt-2">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="obiettivoLungo"
                  value="Dimagrire"
                  checked={additionalAnswers.obiettivoLungo === "Dimagrire"}
                  onChange={handleRadioChange}
                  className="form-radio text-blue-600"
                />
                <span>Dimagrire</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="obiettivoLungo"
                  value="Mantenere"
                  checked={additionalAnswers.obiettivoLungo === "Mantenere"}
                  onChange={handleRadioChange}
                  className="form-radio text-blue-600"
                />
                <span>Mantenere</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="obiettivoLungo"
                  value="Aumentare massa muscolare"
                  checked={
                    additionalAnswers.obiettivoLungo ===
                    "Aumentare massa muscolare"
                  }
                  onChange={handleRadioChange}
                  className="form-radio text-blue-600"
                />
                <span>Aumentare</span>
              </label>
            </div>
          </div>
          {/*DOMANDE PATOLGOIE */}

          <div>
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Patologie:
            </label>
            <div className="flex space-x-4 mt-2">
              <label className="flex items-center space-x-2 cursor-pointer hover:scale-105 transition-transform duration-300">
                <input
                  type="radio"
                  name="patologie"
                  value="Nessuna"
                  checked={additionalAnswers.patologie === "Nessuna"}
                  onChange={(e) =>
                    setAdditionalAnswers({
                      ...additionalAnswers,
                      patologie: e.target.value,
                    })
                  }
                  className="form-radio text-blue-600"
                />
                <span className="text-gray-800 font-medium">Nessuna</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer hover:scale-105 transition-transform duration-300">
                <input
                  type="radio"
                  name="patologie"
                  value="Descrivi"
                  checked={additionalAnswers.patologie === "Descrivi"}
                  onChange={(e) => {
                    setAdditionalAnswers({
                      ...additionalAnswers,
                      patologie: e.target.value,
                    });
                  }}
                  className="form-radio text-blue-600"
                />
                <span className="text-gray-800 font-medium">Descrivi...</span>
              </label>
            </div>

            <div
              className={`transition-all duration-500 ease-in-out overflow-hidden ${
                additionalAnswers.patologie === "Descrivi"
                  ? "max-h-40 opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              <div className="mt-4">
                <input
                  type="text"
                  placeholder="Specificare"
                  value={additionalAnswers.altroPatologie || ""}
                  onChange={(e) =>
                    setAdditionalAnswers({
                      ...additionalAnswers,
                      altroPatologie: e.target.value,
                    })
                  }
                  className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
                />
              </div>
            </div>
          </div>

          {/*DOMANDE ORARIO */}
          <div>
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Orario di preferenza per allenarsi:
            </label>
            <div className="mt-2">
              <input
                type="time"
                name="orarioAllenamento"
                value={additionalAnswers.orarioAllenamento || ""}
                onChange={(e) =>
                  setAdditionalAnswers({
                    ...additionalAnswers,
                    orarioAllenamento: e.target.value,
                  })
                }
                className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
              />
            </div>
          </div>

          <div>
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Hai già fatto sport? Se sì, quali:
            </label>
            <div className="mt-2">
              <select
                name="sport"
                value={additionalAnswers.sport}
                onChange={(e) => {
                  const selectedOptions = Array.from(
                    e.target.selectedOptions
                  ).map((option) => option.value);
                  setAdditionalAnswers({
                    ...additionalAnswers,
                    sport: selectedOptions,
                  });
                }}
                className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
              >
                <option value="calcio">Calcio</option>
                <option value="basket">Basket</option>
                <option value="nuoto">Nuoto</option>
                <option value="tennis">Tennis</option>
                <option value="palestra">Palestra</option>
                <option value="artimarziali">Arti marziali</option>
                <option value="altro">Altro</option>
              </select>
            </div>
          </div>

          <div>
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Utilizzi integratori? Se sì, quali:
            </label>
            <div className="mt-2">
              <textarea
                name="integratori"
                value={additionalAnswers.integratori}
                onChange={(e) =>
                  setAdditionalAnswers({
                    ...additionalAnswers,
                    integratori: e.target.value,
                  })
                }
                placeholder="Indica eventuali integratori che usi..."
                className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
              />
            </div>
          </div>
          <div>
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Ci sono alimenti che ti creano fastidio?
            </label>
            <div className="mt-2">
              <textarea
                name="alimentiFastidio"
                value={additionalAnswers.alimentiFastidio}
                onChange={(e) =>
                  setAdditionalAnswers({
                    ...additionalAnswers,
                    alimentiFastidio: e.target.value,
                  })
                }
                placeholder="Indica eventuali alimenti che ti creano fastidio..."
                className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
              />
            </div>
          </div>
          <div>
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Alimenti di cui non puoi fare a meno:
            </label>
            <div className="mt-2">
              <textarea
                name="alimentiPreferiti"
                value={additionalAnswers.alimentiPreferiti}
                onChange={(e) =>
                  setAdditionalAnswers({
                    ...additionalAnswers,
                    alimentiPreferiti: e.target.value,
                  })
                }
                placeholder="Indica i tuoi alimenti preferiti..."
                className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
              />
            </div>
          </div>
          <div>
            <label className="text-sm text-gray-700 font-semibold uppercase tracking-wide">
              Zone dove tendi ad accumulare grasso:
            </label>
            <div className="mt-2">
              <select
                name="zoneGrasso"
                value={additionalAnswers.zoneGrasso}
                onChange={(e) =>
                  setAdditionalAnswers({
                    ...additionalAnswers,
                    zoneGrasso: e.target.value,
                  })
                }
                className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
              >
                <option value="">Seleziona una zona</option>
                <option value="addome">Addome</option>
                <option value="fianchi">Fianchi</option>
                <option value="cosce">Cosce</option>
                <option value="glutei">Glutei</option>
                <option value="braccia">Braccia</option>
                <option value="schiena">Schiena</option>
                <option value="altro">Altro</option>
              </select>
            </div>
          </div>
        </div>

        {/* Altre domande da aggiungere in modo simile */}

        <button
          type="submit"
          className="w-full px-4 py-4 text-white font-medium rounded-lg bg-blue-600 hover:bg-blue-700 hover:shadow-xl transition duration-300"
        >
          Avanti
        </button>
        <button
          type="button"
          onClick={onBack}
          className="w-full px-4 py-4 text-gray-600 font-medium rounded-lg bg-gray-200 hover:bg-gray-300 hover:shadow-xl transition duration-300"
        >
          Indietro
        </button>
      </form>
    </div>
  );
};

export default Domande;
