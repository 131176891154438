import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import WeightSelector from "../components/WeightSelector";
import { doc, setDoc, getDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

// Imposta l'elemento radice per il modal
Modal.setAppElement("#root");

const AnamnesiModal = ({
  isOpen,
  onRequestClose,
  userId,
  onUpdateAnamnesi,
}) => {
  const [peso, setWeight] = useState(70);
  const [giorni, setGiorni] = useState(1);
  const [anamnesiData, setAnamnesiData] = useState({});
  const [misurazioni, setMisurazioni] = useState([]);
  const [dataSaved, setDataSaved] = useState(false);

  const auth = getAuth();
  const currentUser = auth.currentUser;

  const navigate = useNavigate();

  const navigateToViewWeight = () => {
    navigate("/view-weight", { state: { misurazioni, anamnesiData } }); // Naviga alla pagina ViewWeight
  };

  useEffect(() => {
    console.log("Misurazioni caricate:", misurazioni); // Aggiungi questo log
  }, [misurazioni]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const data = userDocSnap.data();

            const misurazioniArray = data.MisurazionePeso.map(
              (misurazione, idx) => {
                return {
                  peso: misurazione.peso,
                  data: misurazione.data,
                };
              }
            );

            setMisurazioni(misurazioniArray); // Imposta lo stato correttamente
            setAnamnesiData(data);
            setWeight(data.peso || 70);
            setGiorni(data.giorni || 1);
          } else {
            console.log("Nessun documento trovato per l'utente");
          }
        } catch (error) {
          console.error("Errore nel recupero dei dati utente:", error);
        }
      }
    };

    fetchUserData();
  }, [currentUser,dataSaved]);

  useEffect(() => {
    if (onUpdateAnamnesi) {
      onUpdateAnamnesi({ ...anamnesiData, peso, giorni });
    }
  }, [peso, giorni, anamnesiData, onUpdateAnamnesi]);

  const handleSave = async () => {
    try {
      const result = await Swal.fire({
        title: "Confermi di voler salvare?",
        text: "Questa azione salverà i tuoi dati!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sì, salva!",
        cancelButtonText: "Annulla",
      });

      if (result.isConfirmed && peso != null) {
        const giornoDiMisurazione = new Date();
        const giorno = giornoDiMisurazione.getDate();
        const opzioni = { month: "long" };
        const mese = new Intl.DateTimeFormat("it-IT", opzioni).format(
          giornoDiMisurazione
        );
        const anno = giornoDiMisurazione.getFullYear();

        const dataFormattata = `${giorno
          .toString()
          .padStart(2, "0")} ${mese} ${anno}`;

        const userDocRef = doc(db, "users", currentUser.uid);
        await setDoc(
          userDocRef,
          {
            MisurazionePeso: arrayUnion({ peso: peso, data: dataFormattata }),
            peso: peso,
          },
          { merge: true }
        );

        Swal.fire(
          "Salvato!",
          "I tuoi dati sono stati salvati con successo.",
          "success"
        );
        setDataSaved(true);
        onRequestClose();
      }
    } catch (error) {
      Swal.fire(
        "Errore",
        "C'è stato un errore nel salvare i tuoi dati.",
        "error"
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    // Pulizia: rimuovi la classe quando il componente viene smontato
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 overflow-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 overflow-auto max-h-[90vh]">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Informazioni Anamnesi
        </h2>

        {anamnesiData ? (
          <div>
            <h3 className="text-xl font-semibold mb-4 text-center">
              {anamnesiData.nome && anamnesiData.cognome ? (
                <>
                  {anamnesiData.nome.charAt(0).toUpperCase() +
                    anamnesiData.nome.slice(1)}{" "}
                  {anamnesiData.cognome.charAt(0).toUpperCase() +
                    anamnesiData.cognome.slice(1)}
                </>
              ) : (
                <p>Caricamento...</p>
              )}
            </h3>
            <div className="text-lg space-y-3 mb-6">
              <p className="flex justify-between">
                <span className="font-bold text-gray-700">
                  {" "}
                  <img
                    src="/icons8-balance-64.png"
                    alt=""
                    className=" inline-block w-8 h-8 mr-2"
                  />
                  Peso attuale:
                </span>
                <span className="text-gray-600">{peso} kg</span>
              </p>
              <p className="flex justify-between">
                <span className="font-bold text-gray-700">
                  {" "}
                  <img
                    src="/icons8-ruler-64.png"
                    alt=""
                    className=" inline-block w-8 h-8 mr-2"
                  />
                  Altezza:
                </span>
                <span className="text-gray-600">{anamnesiData.altezza} cm</span>
              </p>
              <p className="flex justify-between">
                <span className="font-bold text-gray-700">
                  {" "}
                  <img
                    src="/icons8-gender-64.png"
                    alt=""
                    className=" inline-block w-8 h-8 mr-2"
                  />
                  Sesso:
                </span>
                <span className="text-gray-600">{anamnesiData.sesso}</span>
              </p>
              <p className="flex justify-between">
                <span className="font-bold text-gray-700">
                  <img
                    src="/icons8-schedule-64.png"
                    alt=""
                    className=" inline-block w-8 h-8 mr-2"
                  />{" "}
                  Giorni di Allenamento:
                </span>
                <span className="text-gray-600">{giorni}</span>
              </p>
            </div>

            {anamnesiData.risposte &&
            typeof anamnesiData.risposte === "object" ? (
              <div className="text-lg mb-6 space-y-4">
                <p className="flex justify-between">
                  <span className="font-bold text-gray-700">
                    {" "}
                    <img
                      src="/icons8-spaghetti-64.png"
                      alt=""
                      className=" inline-block w-8 h-8 mr-2"
                    />
                    Dieta:
                  </span>
                  <span className="text-gray-600">
                    {anamnesiData.risposte.dieta}
                  </span>
                </p>
                <p className="flex justify-between inline-block">
                  <span className="font-bold text-gray-700">
                    {" "}
                    <img
                      src="/icons8-treatment-64.png"
                      alt=""
                      className=" inline-block w-8 h-8 mr-2"
                    />
                    Infortunio:
                  </span>
                  <span className="text-gray-600">
                    {anamnesiData.risposte.infortunio}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="font-bold text-gray-700">
                    <img
                      src="/icons8-no-food-64.png"
                      alt=""
                      className=" inline-block w-8 h-8 mr-2"
                    />
                    Problematiche Alimentari:
                  </span>
                  <span className="text-gray-600">
                    {anamnesiData.risposte.problematicheAlimentari}
                  </span>
                </p>
              </div>
            ) : null}

            <div className="mt-6">
              <h4 className="text-lg font-bold text-gray-700">
                Modifica il tuo peso
              </h4>
              <WeightSelector value={peso} onChange={setWeight} />
            </div>
          </div>
        ) : (
          <p className="text-center text-gray-600">
            Nessuna informazione di anamnesi disponibile per l'utente.
          </p>
        )}

        <div className="flex justify-between mt-6">
          <button
            onClick={onRequestClose}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
          >
            Chiudi
          </button>
          
            <button
              onClick={navigateToViewWeight} // Naviga alla pagina ViewWeight
              className="bg-amber-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
            >
              Tutte le misurazioni
            </button>
          
          <button
            onClick={handleSave}
            className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
          >
            Salva
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AnamnesiModal;