import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { db } from "../firebase/firebase";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import ExerciseDetails from "./ExerciseDetails";
import Swal from "sweetalert2";
import { getAuth } from "firebase/auth";
import { FaPlus, FaTrash, FaCheck } from "react-icons/fa"; // Aggiunta di icone
import DatePicker from "react-datepicker"; // Puoi usare una libreria mobile-friendly per il calendario
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement("#root");

const ProgressModal = ({ isOpen, onRequestClose, schedeAllenamento, userId }) => {
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [exerciseDates, setExerciseDates] = useState({});
  const [dateError, setDateError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [exercises, setExercises] = useState({});
  const [completedExercises, setCompletedExercises] = useState({});

  const fetchExerciseCompletions = async () => {
    const completionsCollection = collection(db, "exerciseCompletion");
    try {
      const snapshot = await getDocs(completionsCollection);
      const data = {};
      snapshot.forEach((doc) => {
        const { exerciseName, completed } = doc.data();
        data[doc.id] = { exerciseName, completed };
      });
      return data;
    } catch (error) {
      console.error("Error fetching exercise completions:", error);
      return {};
    }
  };

  useEffect(() => {
    const loadCompletedExercises = async () => {
      const savedCompletions = await fetchExerciseCompletions();
      const formattedCompletions = {};
      for (const key in savedCompletions) {
        formattedCompletions[key] = savedCompletions[key].completed;
      }
      setCompletedExercises(formattedCompletions);
    };

    if (isOpen) {
      loadCompletedExercises();
      fetchExerciseStates();
    }
  }, [isOpen, currentUser]);

  useEffect(() => {
    const auth = getAuth();
    setCurrentUser(auth.currentUser);
  }, []);

  //resetta campo input
  const handleClearDate = (day) => {
    const updatedDates = { ...exerciseDates };
    delete updatedDates[day];
    setExerciseDates(updatedDates);
  };

  // Fetch exercise state from Firebase
  const fetchExerciseStates = async () => {
    if (userId) {
      try {
        const docRef = doc(db, "progressione", currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();

          // Verifica che `data.progressione` sia un array
          if (
            Array.isArray(data.progressione) &&
            data.progressione.length > 0
          ) {
            // Crea un oggetto per mappare le date e gli esercizi per giorno
            const datesMap = {};
            const exercisesMap = {};

            data.progressione.forEach((entry) => {
              if (entry.day && entry.date) {
                datesMap[entry.day] = entry.date;

                // Se ci sono esercizi, mappali per giorno
                if (entry.exercises && Array.isArray(entry.exercises)) {
                  exercisesMap[entry.day] = entry.exercises.map((exercise) => ({
                    ...exercise,
                    series: exercise.series.map((serie) => ({
                      reps: serie.reps || "",
                      kg: serie.kg || "",
                    })),
                  }));
                } else {
                  exercisesMap[entry.day] = [];
                }
              }
            });

            setExercises(exercisesMap); // Imposta lo stato per gli esercizi
          } else {
          }
        } else {
        }
      } catch (error) {
        console.error("Errore nel recuperare lo stato degli esercizi:", error);
      }
    }
  };

  const validateExerciseData = () => {
    for (const day in exerciseDates) {
      const exercisesForDay = schedeAllenamento[0].exercises[day] || [];
      for (const exercise of exercisesForDay) {
        if (exercise.seriesList) {
          for (const series of exercise.seriesList) {
            if (!series.reps) {
              return `Le ripetizioni per la serie ${series.series} dell'esercizio ${exercise.name} non possono essere vuote.`;
            }
          }
        }
      }
    }
    return null;
  };
  //verifica dei campi degli esercizi
  const validateSeriesForDays = () => {
    for (const day in exerciseDates) {
      const date = exerciseDates[day];
      const exercisesForDay = schedeAllenamento[0].exercises[day] || [];

      const hasSeries = exercisesForDay.some((exercise) =>
        (exercise.seriesList || []).some((series) => series.reps)
      );

      if (!hasSeries) {
        return `Per il giorno ${day} con data ${date}, è necessario aggiungere almeno una serie per un esercizio.`;
      }
    }
    return null;
  };

  const saveExerciseStates = async () => {
    if (currentUser) {
      // Esegui la validazione
      const validationError = validateExerciseData() || validateSeriesForDays();
      if (validationError) {
        Swal.fire({
          icon: "error",
          title: "Errore di validazione",
          text: validationError,
          confirmButtonText: "OK",
        });
        return;
      }
  
      const hasDataToSave = Object.keys(exerciseDates).some((day) => {
        const exercisesForDay = schedeAllenamento[0].exercises[day] || [];
        return exercisesForDay.some((exercise) =>
          (exercise.seriesList || []).some((series) => series.reps !== "")
        );
      });
  
      if (!hasDataToSave) {
        Swal.fire({
          icon: "warning",
          title: "Nessun dato da salvare",
          text: "Non ci sono esercizi con serie e ripetizioni inserite.",
          confirmButtonText: "OK",
        });
        return;
      }
  
      const confirmation = await Swal.fire({
        title: "Sei sicuro di voler salvare?",
        text: "Questa azione non può essere annullata.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sì, salva!",
        cancelButtonText: "Annulla",
      });
  
      if (!confirmation.isConfirmed) {
        return;
      }
  
      try {
        const docRef = doc(db, "progressione", currentUser.uid);
  
        const existingData = await getDoc(docRef);
        let existingProgress = [];
  
        if (existingData.exists()) {
          existingProgress = existingData.data().progressione || [];
        }
  
        const newProgress = Object.keys(exerciseDates).map((day) => {
          const date = exerciseDates[day];
          const exercisesForDay = schedeAllenamento[0].exercises[day] || [];
  
          const formattedExercises = exercisesForDay
            .map((exercise) => {
              const validSeries = (exercise.seriesList || []).filter(
                (series) => series.reps !== ""
              );
              return {
                name: exercise.name,
                series: validSeries,
              };
            })
            .filter((exercise) => exercise.series.length > 0);
  
          return {
            day,
            date,
            exercises: formattedExercises,
          };
        });
  
        const updatedProgress = existingProgress.map((existingItem) => {
          const matchingNewItem = newProgress.find(
            (newItem) => newItem.date === existingItem.date
          );
  
          if (matchingNewItem) {
            const mergedExercises = [...existingItem.exercises];
  
            matchingNewItem.exercises.forEach((newExercise) => {
              const existingExerciseIndex = mergedExercises.findIndex(
                (exercise) => exercise.name === newExercise.name
              );
  
              if (existingExerciseIndex !== -1) {
                mergedExercises[existingExerciseIndex].series.push(
                  ...newExercise.series
                );
              } else {
                mergedExercises.push(newExercise);
              }
            });
  
            return {
              ...existingItem,
              exercises: mergedExercises,
            };
          }
  
          return existingItem;
        });
  
        newProgress.forEach((newItem) => {
          const isExisting = updatedProgress.some(
            (existingItem) =>
              existingItem.date === newItem.date &&
              existingItem.day === newItem.day
          );
          if (!isExisting) {
            updatedProgress.push(newItem);
          }
        });
  
        await setDoc(
          docRef,
          { progressione: updatedProgress },
          { merge: true }
        );
  
        Swal.fire({
          icon: "success",
          title: "Salvataggio completato!",
          text: "Lo stato degli esercizi è stato salvato correttamente.",
          confirmButtonText: "OK",
        });
  
        const newCompletions = {};
        const savedExercises = newProgress.reduce((acc, curr) => {
          curr.exercises.forEach((exercise, index) => {
            const exerciseKey = `${curr.day}-${index}`;
            acc[exerciseKey] = true; // Impostiamo come completati solo quelli che sono stati salvati
          });
          return acc;
        }, {});
        
        // Applica i cambiamenti
        setCompletedExercises((prev) => ({
          ...prev,
          ...savedExercises, // Unisci gli esercizi già completati con quelli appena salvati
        }));
        resetSeriesAndReps();
      } catch (error) {
        console.error("Errore nel salvare lo stato degli esercizi:", error);
      }
    }
  };
  

  // Funzione per resettare le serie e ripetizioni
  const resetSeriesAndReps = () => {
    const exercisesForDays = schedeAllenamento[0].exercises;

    // Resetta le serie e ripetizioni per ogni esercizio in ogni giorno
    Object.keys(exercisesForDays).forEach((day) => {
      exercisesForDays[day].forEach((exercise) => {
        exercise.seriesList = []; // Reset delle serie
        // Se hai bisogno di resettare anche altre proprietà, fallo qui
      });
    });

    // Se necessario, aggiorna lo stato dell'interfaccia utente per riflettere il reset
    // updateModalUI(); // Sostituisci con la tua logica per aggiornare l'interfaccia
  };

  useEffect(() => {
    if (isOpen) {
      fetchExerciseStates(); // Recupera gli stati degli esercizi
    }
  }, [isOpen, currentUser]);

  const handleDateChange = async (dateString, day) => {
    const isDateUsed = Object.keys(exerciseDates).some(
      (existingDay) =>
        exerciseDates[existingDay] === dateString && existingDay !== day
    );

    if (isDateUsed) {
      setDateError(
        `La data ${dateString} è già stata selezionata per un altro giorno.`
      );
    } else {
      const updatedDates = { ...exerciseDates, [day]: dateString };
      setExerciseDates(updatedDates);
      setDateError(null);

      // Recupera gli esercizi per la nuova data selezionata
      const exercisesForDate = await fetchExercisesForDate(dateString);

      // Resetta completamenti precedenti
      const newCompletions = {};

      // Filtra gli esercizi in base al giorno corrente
      const exercisesForDay = schedeAllenamento[0].exercises[day] || [];

      // Controlla solo gli esercizi che corrispondono al giorno attuale
      exercisesForDay.forEach((exercise, index) => {
        const exerciseKey = `${day}-${index}`;
        const isCompleted = exercisesForDate.some(
          (ex) => ex.name === exercise.name // Assicurati che il nome dell'esercizio corrisponda
        );

        if (isCompleted) {
          newCompletions[exerciseKey] = true; // Imposta come completato se c'è corrispondenza
        }
      });

      // Aggiorna lo stato degli esercizi completati
      setCompletedExercises(newCompletions);
    }
  };

  // Funzione per recuperare gli esercizi per la data specifica
  const fetchExercisesForDate = async (dateString) => {
    // Recupera gli esercizi dalla collezione "progressione"
    const docRef = doc(db, "progressione", currentUser.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const exercisesForDate = [];

      // Verifica che `data.progressione` sia un array
      if (Array.isArray(data.progressione)) {
        data.progressione.forEach((entry) => {
          if (entry.date === dateString && entry.exercises) {
            entry.exercises.forEach((exercise, index) => {
              exercisesForDate.push({ name: exercise.name, index }); // Aggiungi nome e indice
            });
          }
        });
      }
      return exercisesForDate;
    }
    return [];
  };

  // Handle adding a new series
  const handleAddSeries = (day, index) => {
    const updatedExercises = [...selectedExercises];
    const selectedExercise = schedeAllenamento[0].exercises[day][index];

    const newSeries = {
      series: (selectedExercise.seriesList?.length || 0) + 1,
      reps: "",
      kg: "", // Aggiunto il campo kg
    };

    if (!selectedExercise.seriesList) {
      selectedExercise.seriesList = [];
    }
    selectedExercise.seriesList.push(newSeries);
    setSelectedExercises(updatedExercises);
  };

  // Handle changing reps for a specific series
  const handleRepsChange = (day, index, seriesIndex, value, field) => {
    const updatedExercises = [...selectedExercises];
    const selectedExercise = schedeAllenamento[0].exercises[day][index];

    // Controlla se stiamo cambiando le ripetizioni o i kg
    if (field === "reps") {
      selectedExercise.seriesList[seriesIndex].reps = value.replace(
        /[^0-9]/g,
        ""
      );
    } else if (field === "kg") {
      selectedExercise.seriesList[seriesIndex].kg = value.replace(
        /[^0-9.]/g,
        ""
      ); // Consenti solo numeri e punti per i kg
    }

    setSelectedExercises(updatedExercises);
  };

  // Handle removing the last series
  const handleRemoveSeries = (day, index) => {
    const updatedExercises = [...selectedExercises];
    const selectedExercise = schedeAllenamento[0].exercises[day][index];

    if (selectedExercise.seriesList?.length > 0) {
      selectedExercise.seriesList.pop();
    }

    setSelectedExercises(updatedExercises);
  };

  const toggleExerciseCompletion = async (exerciseKey, exerciseName, day) => {
    const newCompletions = { ...completedExercises };
    const isCurrentlyCompleted = newCompletions[exerciseKey];

    if (!isCurrentlyCompleted) {
      newCompletions[exerciseKey] = exerciseName;

      const saveSuccess = await saveExerciseStates();
      if (saveSuccess) {
        setCompletedExercises(newCompletions);
      }
    } else {
      delete newCompletions[exerciseKey];
      await removeExerciseState(exerciseKey, day); // Rimuovi l'esercizio dal DB
    }

    setCompletedExercises(newCompletions);

    await setDoc(
      doc(db, "exerciseCompletion", currentUser.uid),
      {
        [exerciseKey]: { exerciseName, completed: !isCurrentlyCompleted },
      },
      { merge: true }
    );
  };

  const removeExerciseState = async (exerciseKey, day) => {
    if (currentUser) {
      try {
        const docRef = doc(db, "progressione", currentUser.uid);

        // Recupera i dati esistenti
        const existingData = await getDoc(docRef);
        if (existingData.exists()) {
          const existingProgress = existingData.data().progressione || [];

          // Aggiorna i dati esistenti per rimuovere l'esercizio dal giorno specificato
          const updatedProgress = existingProgress.map((item) => {
            // Controlla se il giorno corrente corrisponde al giorno dell'allenamento
            if (item.day === day) {
              const updatedExercises = item.exercises.filter(
                (exercise) => exercise.name !== exerciseKey
              );

              return {
                ...item,
                exercises: updatedExercises,
              };
            }
            return item; // Restituisci l'oggetto invariato se il giorno non corrisponde
          });

          // Aggiorna il documento nel database
          await updateDoc(docRef, { progressione: updatedProgress });

          Swal.fire({
            icon: "success",
            title: "Esercizio rimosso!",
            text: "L'esercizio è stato rimosso correttamente.",
            confirmButtonText: "OK",
          });
        } else {
          console.warn("Nessun documento trovato per l'utente."); // Debug: avviso se il documento non esiste
        }
      } catch (error) {
        console.error("Errore nel rimuovere lo stato dell'esercizio:", error);
      }
    } else {
      console.warn("Nessun utente autenticato."); // Debug: avviso se l'utente non è autenticato
    }
  };

  const renderDetails = (exercises) => {
    return Object.keys(exercises).map((day) => {
      const savedDate = exerciseDates[day];
      return (
        <ExerciseDetails
          key={day}
          day={day}
          exercises={exercises}
          savedDate={savedDate}
          handleDateChange={handleDateChange}
          handleClearDate={handleClearDate}
          completedExercises={completedExercises} // Passiamo l'elenco degli esercizi completati
          handleAddSeries={handleAddSeries}
          handleRemoveSeries={handleRemoveSeries}
          handleRepsChange={handleRepsChange}
          dateError={dateError}
        />
      );
    });
  };
  
  const userExercises = schedeAllenamento.filter(
    (scheda) => scheda.id === userId
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center p-2 sm:p-4 bg-black bg-opacity-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-4 sm:p-6 max-h-screen relative overflow-y-auto">
        <h2 className="text-lg sm:text-2xl font-bold mb-4 text-center">
          Progressione personale
        </h2>

        {userExercises.length > 0 ? (
          <div>
            <div className="space-y-4 overflow-auto container-details">
              {userExercises.map((scheda) => (
                <div
                  key={scheda.id}
                  className="p-3 border border-gray-300 rounded-lg shadow-sm"
                >
                  <h4 className="text-lg font-semibold mb-2 text-center">
                    {scheda.id.toUpperCase()}
                  </h4>
                  {renderDetails(scheda.exercises)}
                </div>
              ))}
            </div>

            <div className="mt-4 flex flex-col space-y-2">
              <button
                onClick={() => {
                  setExerciseDates({});
                  onRequestClose();
                }}
                className="w-full bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
              >
                Chiudi
              </button>
              <button
                onClick={saveExerciseStates}
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
              >
                Salva progressi
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p className="text-center">
              Nessuna progressione disponibile per l'utente {userId}
            </p>
            <button
              onClick={onRequestClose}
              className="w-full bg-red-500 text-white py-2 px-4 mt-4 rounded-lg hover:bg-red-600"
            >
              Chiudi
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ProgressModal;
