import React, { useState, useEffect } from "react";
import { FaHome, FaPlus, FaSave, FaTrashAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase"; // Assicurati che il tuo db sia correttamente importato
import Lottie from "lottie-react";
import Warning from "../../assets/lottie/wired-flat-1140-error-hover-oscillate.json";

const EditIntegration = () => {
  const { id } = useParams(); // Estrai l'ID del documento dalla URL
  const [integrationData, setIntegrationData] = useState({
    integrationName: "",
    weight: "",
    timeOfDay: "Mattina", // Nuovo campo per la selezione
  });
  const [weekIntegrations, setWeekIntegrations] = useState([]); // Gestisce le integrazioni della settimana
  const [timestamp, setTimestamp] = useState(null); // Stato per il timestamp
const navigate = useNavigate();
  // Funzione per recuperare le integrazioni dal database
  const fetchIntegrations = async () => {
    try {
      const docRef = doc(db, "Integrazioni", id); // Ottieni il riferimento al documento
      const docSnap = await getDoc(docRef); // Recupera il documento

      if (docSnap.exists()) {
        const data = docSnap.data();
        setWeekIntegrations(data.settimana || []); // Imposta le integrazioni della settimana
        setTimestamp(data.timestamp?.toDate().toLocaleString() || "N/A"); // Imposta il timestamp
      } else {
        console.log("Documento non trovato!");
      }
    } catch (error) {
      console.error("Errore durante il recupero delle integrazioni: ", error);
    }
  };

  // Chiamata per ottenere i dati quando il componente viene montato
  useEffect(() => {
    fetchIntegrations();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIntegrationData({
      ...integrationData,
      [name]: value,
    });
  };

  const handleAddIntegration = (e) => {
    e.preventDefault();
    if (integrationData.integrationName && integrationData.weight) {
      setWeekIntegrations((prevIntegrations) => [
        ...prevIntegrations,
        integrationData,
      ]);
      setIntegrationData({
        integrationName: "",
        weight: "",
        timeOfDay: "Mattina", // Reset del campo selezione
      });
    }
  };

  // Funzione per il salvataggio su Firebase con timestamp
  const handleSave = async () => {
    try {
      const docRef = doc(db, "Integrazioni", id); // Ottieni il riferimento al documento
      await setDoc(docRef, {
        settimana: weekIntegrations, // Salva solo la settimana attiva
        timestamp: new Date(), // Aggiungi il timestamp (data e ora correnti)
      });

      console.log(
        "Le integrazioni della settimana sono state salvate su Firebase"
      );
      setTimestamp(new Date().toLocaleString()); // Aggiungi il timestamp aggiornato
    } catch (error) {
      console.error("Errore durante il salvataggio su Firebase: ", error);
    }
  };

  const handleDeleteIntegration = (indexToRemove) => {
    setWeekIntegrations((prevIntegrations) =>
      prevIntegrations.filter((_, index) => index !== indexToRemove)
    );
  };

  const goHome  = ()=>{
    navigate("/home");
  }
  return (
    <div className="flex flex-col items-center px-4 py-8">
      {/* Titolo */}
      <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-8 text-center text-indigo-600">
        Gestione Integrazioni
      </h2>
      {/* Visualizzazione del timestamp */}
      {timestamp != null ? (
        <div className="mb-4 text-gray-600">
          <strong>Ultimo aggiornamento:</strong> {timestamp}
        </div>
      ) : (
        <p>Non hai ancora assegnato delle integrazioni</p>
      )}

      {/* Form per inserire nuovi dati */}
      <form onSubmit={handleAddIntegration} className="w-full max-w-4xl mb-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
          <div className="flex flex-col">
            <label className="mb-2 text-lg font-semibold text-gray-700">
              Nome Integrazione
            </label>
            <input
              type="text"
              name="integrationName"
              value={integrationData.integrationName}
              onChange={handleChange}
              className="border border-gray-300 rounded-md px-4 py-2 focus:ring focus:ring-indigo-200 text-sm md:text-base"
              placeholder="Inserisci il nome (max. 15 caratteri)"
              maxLength={15}
            />
          </div>

          <div className="flex flex-col">
            <label className="mb-2 text-lg font-semibold text-gray-700">
              Peso (in grammi)
            </label>
            <input
              type="number"
              name="weight"
              value={integrationData.weight}
              onChange={handleChange}
              className="border border-gray-300 rounded-md px-4 py-2 focus:ring focus:ring-indigo-200 text-sm md:text-base"
              placeholder="Inserisci il peso"
            />
          </div>

          {/* Nuovo selettore per il momento della giornata */}
          <div className="flex flex-col">
            <label className="mb-2 text-lg font-semibold text-gray-700">
              Momento della Giornata
            </label>
            <select
              name="timeOfDay"
              value={integrationData.timeOfDay}
              onChange={handleChange}
              className="border border-gray-300 rounded-md px-4 py-2 focus:ring focus:ring-indigo-200 text-sm md:text-base"
            >
              <option value="Mattina">Mattina</option>
              <option value="Intra workout">Intra workout</option>
              <option value="Post workout">Post workout</option>
              <option value="Pre nanna">Pre nanna</option>
            </select>
          </div>

          {/* Pulsante per aggiungere */}
          <div className="flex flex-col self-end">
            <button
              type="submit"
              className="flex items-center justify-center bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-6 rounded-md transition-all duration-200 "
            >
              <FaPlus className="mr-2" /> Aggiungi Integrazione
            </button>
          </div>
        </div>
      </form>

      {/* Resoconto della settimana */}
      <div className="mt-12 w-full max-w-2xl mx-auto">
        {weekIntegrations.length > 0 ? (
          <div className="bg-white border border-gray-300 shadow-lg rounded-lg p-6">
            <h4 className="text-xl font-bold text-indigo-600 mb-4">
              Integrazioni della Settimana
            </h4>
            <div className="w-full overflow-hidden">
              <table className="min-w-full table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left font-semibold text-gray-700">
                      Nome
                    </th>
                    <th className="px-4 py-2 text-left font-semibold text-gray-700">
                      Momento
                    </th>
                    <th className="px-4 py-2 text-left font-semibold text-gray-700">
                      Grammi
                    </th>
                    <th className="px-4 py-2 text-left font-semibold text-gray-700">
                      Azioni
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {weekIntegrations.map((integration, i) => (
                    <tr key={i} className="border-t border-gray-200">
                      <td className="px-4 py-2 text-gray-700 truncate">
                        {integration.integrationName}
                      </td>
                      <td className="px-4 py-2 text-gray-700">
                        {integration.timeOfDay}
                      </td>
                      <td className="px-4 py-2 text-gray-700 font-semibold">
                        {integration.weight} g
                      </td>
                      <td className="px-4 py-2 text-gray-700">
                        <FaTrashAlt
                          className="text-red-600 cursor-pointer hover:text-red-800"
                          onClick={() => handleDeleteIntegration(i)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center ">
            <Lottie
              animationData={Warning}
              loop
              autoplay
              style={{ width: 200, height: 200 }}
            />

            <div className="mt-4 text-gray-600">
              Nessuna integrazione disponibile
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center items-center w-full gap-4">
        <button
          onClick={goHome}
          className="flex justify-center w-full max-w-xs flex items-center mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-md transition-all duration-200"
        >
          <FaHome className="mr-2" />  Home
        </button>
        {/* Pulsante per salvare */}
        {weekIntegrations.length > 0 ? (
          <button
            onClick={handleSave}
            className="flex justify-center w-full max-w-xs flex items-center mt-6 bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-md transition-all duration-200"
          >
            <FaSave className="mr-2" /> Salva Dati
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default EditIntegration;