import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { getDocs, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import SchedaModal from "../components/SchedaModal";
import AnamnesiModal from "../components/AnamnesiModal";
import ProgressModal from "../components/ProgressModal";
import NutritionModal from "../components/NutritionModal";
import Box from "../components/Box";
import { FaDumbbell, FaAppleAlt, FaChartLine, FaClipboardList } from "react-icons/fa";
import Header from "../components/header/index";

const BoxContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAnamnesiModalOpen, setIsAnamnesiModalOpen] = useState(false);
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
  const [isNutritionModalOpen, setIsNutritionModalOpen] = useState(false);
  const [nutritionData, setNutritionData] = useState(null);

  const [schede, setSchede] = useState([]);
  const [anamnesiData, setAnamnesiData] = useState(null);
  const [userName, setUserName] = useState("");
  const { currentUser } = useAuth();

  // Stato per gestire la visibilità dell'header
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);



  
  useEffect(() => {
    const fetchUserName = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, "users", currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserName(`${userData.nome} ${userData.cognome}`);
          }
        } catch (error) {
          console.error("Errore nel recuperare i dati dell'utente: ", error);
        }
      }
    };

    fetchUserName();
  }, [currentUser]);

  const handleApriScheda = async () => {
    if (currentUser) {
      try {
        const userId = userName;
        const querySnapshot = await getDocs(collection(db, "schedeAllenamento"));
        const schede = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const userSchede = schede.filter((scheda) => scheda.id === userId);

        setSchede(userSchede);
        setIsModalOpen(true);
        setIsHeaderVisible(false); // Nascondi l'header
      } catch (error) {
        console.error("Errore nel recuperare i documenti: ", error);
      }
    } else {
      console.log("Utente non autenticato");
    }
  };

  const handleApriAnamnesi = async () => {
    if (currentUser) {
      try {
        const userId = userName;
        const userAnamnesiRef = doc(db, "anamnesi", userId);
        const userAnamnesiSnap = await getDoc(userAnamnesiRef);

        if (userAnamnesiSnap.exists()) {
          const userAnamnesi = userAnamnesiSnap.data();
          setAnamnesiData(userAnamnesi);
          setIsAnamnesiModalOpen(true);
        } else {
          const userDoc = await getDoc(doc(db, "users", currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const { nome, cognome, email, altezza, peso, giorni } = userData;
            await setDoc(userAnamnesiRef, { nome, cognome, email, altezza, peso, giorni });
            setAnamnesiData({ nome, cognome, email, altezza, peso, giorni });
            setIsAnamnesiModalOpen(true);
          } else {
            console.error("Dati dell'utente non trovati nella collezione users.");
          }
        }
        setIsHeaderVisible(false); // Nascondi l'header
      } catch (error) {
        console.error("Errore nel gestire anamnesi: ", error);
      }
    } else {
      console.log("Utente non autenticato");
    }
  };

  const handleApriNutrition = async () => {
    if (currentUser) {
      try {
        const userId = currentUser.uid;
        const userNutritionRef = doc(db, "dieta", userId);
        const userNutritionSnap = await getDoc(userNutritionRef);

        if (userNutritionSnap.exists()) {
          const userNutrition = userNutritionSnap.data();
          setNutritionData(userNutrition);
          setIsNutritionModalOpen(true);
        } else {
          setNutritionData({ integrazione: {}, noteAggiuntive: "" });
          setIsNutritionModalOpen(true);
        }
        setIsHeaderVisible(false); // Nascondi l'header
      } catch (error) {
        console.error("Errore nel gestire i dati nutrizionali: ", error);
      }
    } else {
      console.log("Utente non autenticato");
    }
  };

  const handleApriProgressione = async () => {
    if (currentUser) {
      try {
        const userId = userName;
        const querySnapshot = await getDocs(collection(db, 'schedeAllenamento'));
        const schede = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const userSchede = schede.filter(scheda => scheda.id === userId);

        setSchede(userSchede);
        setIsProgressModalOpen(true);
        setIsHeaderVisible(false); // Nascondi l'header
      } catch (error) {
        console.error('Errore nel recuperare i documenti: ', error);
      }
    } else {
      console.log('Utente non autenticato');
    }
  };

  // Funzione per chiudere i modali e ripristinare la visibilità dell'header
  const closeModal = () => {
    setIsModalOpen(false);
    setIsAnamnesiModalOpen(false);
    setIsProgressModalOpen(false);
    setIsNutritionModalOpen(false);
    setIsHeaderVisible(true); // Mostra di nuovo l'header
  };

  return (
    <div className="home-container">
      <Header isVisible={isHeaderVisible} />
      <div className="">
        <div className="flexed-boxes">
          <Box
            title="Allenamento"
            backgroundColor="#F86614B8"
            buttonText="Dettagli allenamento"
            onButtonClick={handleApriScheda}
            description={
              "Scopri il programma di allenamento su misura che ti aiuta a migliorare la tua forma fisica. Ogni esercizio è studiato per te, aiutandoti a raggiungere i tuoi obiettivi di fitness."
            }
            icon={FaDumbbell}
          />
          <Box
            title="Dieta"
            backgroundColor="#0094FFB8"
            buttonText="Dettagli dieta"
            onButtonClick={handleApriNutrition}
            description={
              "Adotta una dieta equilibrata con il piano nutrizionale su misura. Ricevi pasti salutari e gustosi per raggiungere i tuoi obiettivi di salute e benessere, supportati da esperti nutrizionisti."
            }
            icon={FaAppleAlt}
          />
        </div>
        <div className="flexed-boxes">
          <Box
            title="Progressione"
            backgroundColor="#E26274"
            buttonText="Dettagli progressione"
            onButtonClick={handleApriProgressione}
            description={
              "Monitora i tuoi progressi con strumenti avanzati. Ogni fase del percorso sarà tracciata, valutata e ottimizzata al fine di raggiungere i tuoi obiettivi di fitness e miglioramento."
            }
            icon={FaChartLine}
          />
          <Box
            title="Anamnesi"
            backgroundColor="#78589F"
            buttonText="Dettagli anamnesi"
            onButtonClick={handleApriAnamnesi}
            description={
              "Analizza la tua anamnesi dettagliata con il nostro servizio. Esamina la tua storia medica per pianificare al meglio il tuo percorso di salute e ottimizzare le strategie di miglioramento."
            }
            icon={FaClipboardList}
          />
        </div>
      </div>
      <SchedaModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        schedeAllenamento={schede}
        userId={userName}
      />
      <ProgressModal
        isOpen={isProgressModalOpen}
        onRequestClose={closeModal}
        schedeAllenamento={schede}
        userId={userName}
      />
      <AnamnesiModal
        isOpen={isAnamnesiModalOpen}
        onRequestClose={closeModal}
        userId={userName}
        anamnesiData={anamnesiData}
      />
      <NutritionModal
        isOpen={isNutritionModalOpen}
        onRequestClose={closeModal}
        userId={currentUser.uid}
        onUpdateNutrition={(updatedData) => setNutritionData(updatedData)}
        nutritionData={nutritionData}
      />
    </div>
  );
};

export default BoxContainer;
