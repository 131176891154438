import React, { useState, useEffect } from "react";
import { db, auth } from "../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { FaChartLine, FaArrowLeft, FaEye, FaTimes } from "react-icons/fa";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, startOfWeek } from "date-fns"; // Assicurati di installare date-fns
import Lottie from "lottie-react";
import LoadingAnimation from '../assets/lottie/wired-outline-667-biking-hover-pinch.json';
import { motion } from 'framer-motion'; // Importa framer-motion
import Header from "./header";
const ProgressDataPage = () => {
  const [progressData, setProgressData] = useState([]);
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = auth.currentUser?.uid;
  const [openWeeks, setOpenWeeks] = useState([]);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const weeksPerPage = 5; // Numero di settimane da visualizzare per volta

  const navigate = useNavigate();

  const toggleWeek = (index) => {
    setOpenWeeks((prev) =>
      prev.includes(index)
        ? prev.filter((week) => week !== index)
        : [...prev, index]
    );
  };

  const fetchProgressData = async () => {
    try {
      setLoading(true);

      const progressDocRef = doc(db, "progressione", userId);
      const progressDocSnap = await getDoc(progressDocRef);

      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (progressDocSnap.exists() && userDocSnap.exists()) {
        const rawData = progressDocSnap.data().progressione;
        const userData = userDocSnap.data();

        if (rawData && rawData.length > 0) {
          const groupedData = groupByWeek(rawData);
          setProgressData(groupedData);
          setUsersData(userData);
        } else {
          setError("Nessun dato di progressione trovato per questo utente.");
        }
      } else {
        setError("Nessun dato trovato per questo utente.");
      }
    } catch (err) {
      setError("Errore nel recupero dei dati.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Funzione per raggruppare i dati per settimana
  const groupByWeek = (data) => {
    const weeks = {};

    data.forEach((dayData) => {
      const date = new Date(dayData.date); // Assicurati che `dayData.date` sia una stringa in formato ISO
      const weekStart = startOfWeek(date); // Ottieni la data di inizio settimana

      // Formatta la settimana come stringa
      const weekKey = format(weekStart, "yyyy-MM-dd");

      // Se non esiste la chiave per la settimana, creala
      if (!weeks[weekKey]) {
        weeks[weekKey] = {
          weekStart: weekStart,
          days: [],
        };
      }

      // Aggiungi il giorno ai giorni della settimana
      weeks[weekKey].days.push(dayData);
    });

    // Restituisci l'array di settimane invertito
    return Object.entries(weeks)
      .map(([key, value]) => ({
        weekStart: value.weekStart,
        days: value.days,
      }))
      .sort((a, b) => b.weekStart - a.weekStart); // Ordina le settimane in ordine decrescente
  };

  useEffect(() => {
    if (userId) {
      fetchProgressData();
    }
  }, [userId]);

  const nextPage = () => {
    if (currentWeekIndex + weeksPerPage < progressData.length) {
      setCurrentWeekIndex((prev) => prev + weeksPerPage);
    }
  };

  const prevPage = () => {
    if (currentWeekIndex - weeksPerPage >= 0) {
      setCurrentWeekIndex((prev) => prev - weeksPerPage);
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Lottie animationData={LoadingAnimation} loop autoplay style={{ width: 200, height: 200 }} />
        <p className="mt-4 text-gray-600">Caricamento in corso...</p> {/* Messaggio di caricamento */}
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-blue-100 p-6">
        <p className="text-center text-red-600 text-lg mb-6">{error}</p>
        <div className="flex justify-center mt-8">
          <button
            onClick={() => navigate("/home")}
            className="bg-blue-800 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-700 transition-colors"
          >
            Torna alla Home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-6 overflow-y-auto" style={{
    }}>
      <div className="mt-16">
      <Header isVisible={true} />
      </div>
      {/* Icona al centro in alto */}
      <button
          onClick={() => navigate("/home")}
          className="flex items-center text-blue-500 hover:text-blue-700 mr-auto  md:hidden" // Mostra solo su schermi 'md' e più grandi
        >
          <FaArrowLeft size={24} />
          <span className="ml-1">Home</span>
        </button>
      <div className="flex justify-center mb-6">
     
        <div
          className="bg-white p-4 rounded-full shadow-lg border border-blue-500"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
        >
          <FaChartLine size={48} className="text-blue-500" />
        </div>
      </div>

      <h1 className="text-4xl font-bold text-center text-blue-500 mb-8 ">
        Progressione {usersData ? usersData.nome.toUpperCase() : ""}
      </h1>

      {progressData.length > 0 ? (
        <div className="space-y-4">
           <div className="text-center">
            <label >
              Qui visualizzi tutti gli allenamenti divisi in{" "}
              <strong>settimane</strong>, a partire dall'
              <strong>ultima inserita</strong>
            </label>
          </div>
          {progressData.slice(currentWeekIndex, currentWeekIndex + weeksPerPage).map((weekData, index) => (
            <motion.div
              key={index}
              className="bg-white  rounded-lg p-4 border border-blue-500"
              initial={{ opacity: 0, y: 20 }} // Inizio dell'animazione
              animate={{ opacity: 1, y: 0 }} // Stato finale dell'animazione
              transition={{ duration: 0.5 }} // Durata dell'animazione
            >
              <div
                onClick={() => toggleWeek(index + currentWeekIndex)} // Adjust index for toggling
                className="flex justify-between items-center cursor-pointer hover:bg-gray-200 p-2 rounded"
              >
                <h2 className="text-xl font-semibold text-black">
                  Settimana da: {format(weekData.weekStart, 'dd MMM yyyy')}
                </h2>
                <span>{openWeeks.includes(index + currentWeekIndex) ? <FaTimes style={{width:25, height:25, color:'red'}} /> : <FaEye  style={{width:25, height:25}}  className="text-blue-500"/>}</span>
              </div>

              {openWeeks.includes(index + currentWeekIndex) && (
                <div className="mt-4 ">
                  {weekData.days.map((dayData, dayIndex) => (
                    <div key={dayIndex} className="border-b border-gray-300 pb-2 mt-4">
                      <div className="flex items-center mb-2">
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          size="2xl"
                          className="text-blue-600 mr-4"
                        />
                        <h3 className="text-xl font-semibold text-blue-600">
                          <span className="text-2xl">{dayData.date} - Giorno {dayData.day}</span>
                        </h3>
                      </div>
                      <div className="space-y-2">
                        {dayData.exercises && dayData.exercises.length > 0 ? (
                          dayData.exercises.map((exercise, idx) => (
                            <div key={idx} className="border-gray-500 pb-2 text-gray-200">
                              <h3 className="text-xl text-black font-bold">{(exercise?.name).toUpperCase()}</h3>
                              {exercise.series && exercise.series.length > 0 ? (
                                <ul className="list-disc list-inside text-md text-gray-600 mt-2">
                                  {exercise.series.map((serie, sIndex) => (
                                    <li key={sIndex} className="ml-4">
                                      <span className="font-semibold text-black text-lg">
                                        Serie {sIndex + 1}:
                                      </span>
                                      <span className="text-black">
                                        <span className="text-xl ml-2">{serie.reps} </span>
                                        ripetizioni da <span className="text-xl">{serie.kg}</span> kg
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p className="text-gray-500">
                                  Nessuna serie disponibile per questo esercizio.
                                </p>
                              )}
                            </div>
                          ))
                        ) : (
                          <p className="text-gray-500">
                            Nessun esercizio disponibile per questo giorno.
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </motion.div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-300 mt-6">
          Nessun dato di progressione disponibile.
        </p>
      )}

      <div className="flex justify-between mt-4">
        <button
          onClick={prevPage}
          className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50 font-bold"
          disabled={currentWeekIndex === 0}
        >
          Precedente
        </button>
        <button
          onClick={nextPage}
          className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50 font-bold"
          disabled={currentWeekIndex + weeksPerPage >= progressData.length}
        >
          Successivo
        </button>
      </div>

    
    </div>
  );
};

export default ProgressDataPage;
