import Login from "./components/auth/login";
import Register from "./components/auth/register";
import { Navigate, useLocation  } from "react-router-dom";
import Header from "./components/header";
import Home from "./components/home";
import UserDetails from "./components/user/UserDetails";
import EditUser from "./components/user/EditUser";
import { AuthProvider  } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";
import EditExercise from "./components/user/EditExercise";
import NotFound from "./components/notFound/NotFound";
import '../src/App.css';
import EditNutrition from "./components/user/EditNutrition";
import ViewProgression from "./components/user/ViewProgression";
import ViewInformations from "./components/user/ViewInformations";
import ProgressDataPage from "./components/ProgressDataPage";
import ViewWeight from "./components/user/ViewWeight";
import EditIntegration from "./components/user/EditIntegration";
import ViewIntegration from "./components/user/ViewIntegration";
function App() {
  const location = useLocation();  // Usa useLocation per ottenere il percorso corrente
 
  const routesArray = [
    {
      path: "/",
      element: <Navigate to="/login" />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
   
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/user/:id",
      element: <UserDetails />,
    },
    {
      path: "/edit-user/:id",
      element: <EditUser />,
    },
    {
      path: "/edit-exercise/:id",
      element: <EditExercise />,
    },
    {
      path: "/edit-nutrition/:id",
      element: <EditNutrition />,
    },
    {
      path: "/view-progression/:id",
      element: <ViewProgression />,
    },
    {
      path: "/view-weight/:id?",
      element: <ViewWeight />,
    },
    {
      path: "/view-informations/:id",
      element: <ViewInformations/>,
    },
    {
      path: "/view-integration/:id",
      element: <ViewIntegration/>,
    },
    {
      path: "/progress-data/:id",  // Nuova rotta per visualizzare i dati di progressione per gli utenti
      element: <ProgressDataPage />,
    },
    {
      path: "/edit-integration/:id",
      element: <EditIntegration />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];
  let routesElement = useRoutes(routesArray);

  const shouldHideHeader =
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    routesElement.type === NotFound; // Controlla se routesElement è di tipo NotFound

  return (
    
    <AuthProvider>
      {/*Mi serve per la navigazione globale cosi facendo non ho problemi quando faccio lo swipe */}
        {!shouldHideHeader && <Header />} {/* Mostra l'Header solo se non è una rotta di autenticazione o NotFound */}
   
      <div className="w-full h-screen flex flex-col">{routesElement}</div>
    </AuthProvider>
  );
}

export default App;