import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../assets/lottie/gymLogin2.json'; // Cambia il percorso al tuo file di animazione

const LottieAnimation = () => {
    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)', // Background semitrasparente
                zIndex: 9999, // Assicurati che sia sopra gli altri elementi
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Player
                autoplay
                loop={false} // L'animazione non dovrebbe essere in loop
                src={animationData}
                style={{ width: '150px', height: '150px' }} // Adatta la dimensione dell'animazione
            />
        </div>
    );
};

export default LottieAnimation;
