import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { FaClipboardList, FaTrash, FaEdit } from "react-icons/fa"; // Se vuoi gestire l'icona per cancellare
import Swal from "sweetalert2";
const ViewInformations = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [lastLogin, setLastLogin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [giorni, setGiorni] = useState(1); // Stato per il numero di giorni
  const [giorniError, setGiorniError] = useState(""); // Per gestire errori di validazione
  const [isModalOpen, setIsModalOpen] = useState(false); // Stato per il modal

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Mese attuale (0 = gennaio, 11 = dicembre)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Anno attuale

  const closeUserInfo = () => {
    navigate("/home");
  };

  // Mappa dei mesi in italiano per il parsing
  const monthNames = [
    "gennaio",
    "febbraio",
    "marzo",
    "aprile",
    "maggio",
    "giugno",
    "luglio",
    "agosto",
    "settembre",
    "ottobre",
    "novembre",
    "dicembre",
  ];

  // Funzione per convertire la data dal formato "23 ottobre 2024" in un oggetto Date
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split(" ");
    const monthIndex = monthNames.indexOf(month.toLowerCase()); // Ottieni l'indice del mese
    if (monthIndex === -1) return null; // Se il mese non è valido
    return new Date(parseInt(year), monthIndex, parseInt(day)); // Crea un oggetto Date
  };

  //FUNZIONE PER SALVARE I GIORNI

  const saveGiorni = async () => {
    if (giorni < 1 || giorni > 5 || isNaN(giorni)) {
      setGiorniError("Il valore deve essere tra 1 e 5");
      return; // Non permette il salvataggio se il valore non è valido
    }

    try {
      const userRef = doc(db, "users", id);
      await updateDoc(userRef, { giorni }); // Aggiorna il valore su Firebase
      setIsModalOpen(false); // Chiudi il modal
      Swal.fire("Operazione avvenuta con successo", "", "success");
    } catch (error) {
      Swal.fire("Operazione compromessa", "", "error");
    }
  };

  useEffect(() => {

    const fetchData = async () => {

      try {
        const userRef = doc(db, "users", id);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setSelectedUser(userData);
          setLastLogin(userData.metadata?.lastSignInTime);
        } else {
          setError("Nessun utente trovato");
        }
      } catch (error) {
        setError("Errore nel recupero dei dati");
        console.error("Errore nel recupero dei dati:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, saveGiorni]);

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen text-center text-red-500">
        <p>{error}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen text-center">
        <p>Caricamento...</p>
      </div>
    );
  }

  const renderUserInfo = (label, icon, value) => {
    return value !== null && value !== undefined ? (
      <div className="flex bg-white p-4 rounded-lg shadow-md border border-indigo-300 items-center">
        <strong className="text-lg">{label}:</strong>
        <p className="text-gray-900 text-xl ml-2">{value}</p>
        {icon && <div className="ml-auto">{icon}</div>}
      </div>
    ) : null;
  };

  const renderField = (label, value) =>
    value ? (
      <div className="bg-white shadow-md rounded-lg p-4 mb-4 border border-indigo-300">
        <h3 className="text-lg font-semibold text-gray-700">{label}</h3>
        <p className="text-gray-900 mt-2 text-xl">{value}</p>
      </div>
    ) : null;

  // Filtra le misurazioni per il mese e l'anno corrente
  const filteredMisurazioni = selectedUser.MisurazionePeso
    ? selectedUser.MisurazionePeso.filter((misurazione) => {
        const misurazioneDate = parseDate(misurazione.data);
        return (
          misurazioneDate &&
          misurazioneDate.getMonth() === currentMonth &&
          misurazioneDate.getFullYear() === currentYear
        );
      })
    : [];

  // Funzione per cambiare mese avanti o indietro
  const changeMonth = (direction) => {
    let newMonth = currentMonth + direction;
    let newYear = currentYear;

    if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    } else if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    }

    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const getMonthName = (monthIndex) => {
    return monthNames[monthIndex];
  };

  const formatDate = (dateString) => {
    const date = parseDate(dateString);
    if (!date) return "Data non valida";
    return `${date.getDate()} ${getMonthName(
      date.getMonth()
    )} ${date.getFullYear()}`;
  };
  return (
    <div className="bg-indigo-400 w-full px-4 py-4 rounded-b-xl rounded-t-none  mb-4">
      <div className="flex justify-center mt-2">
        <div
          className="bg-[#FFFFFF3B] rounded-full flex justify-center items-center mb-2.5 w-32 h-32 " // Usa classi responsive per la dimensione
        >
          <FaClipboardList className="icon text-white text-3xl w-20 h-20 " />
        </div>
      </div>
      {selectedUser && (
        <div className="bg-white p-4 mt-6 py-10 rounded-lg shadow-md relative">
          <h2 className="text-3xl font-bold mb-4">Informazioni Utente</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {renderUserInfo("Nome", null, selectedUser.nome)}
            {renderUserInfo("Cognome", null, selectedUser.cognome)}
            {renderUserInfo("Email", null, selectedUser.email)}
            {selectedUser.MisurazionePeso &&
            selectedUser.MisurazionePeso.length > 0
              ? // Se ci sono misurazioni, prendi l'ultima
                renderUserInfo(
                  "Peso attuale",
                  null,
                  `${
                    selectedUser.MisurazionePeso[
                      selectedUser.MisurazionePeso.length - 1
                    ].peso
                  } kg` // Prendi l'ultimo valore di peso
                )
              : // Se non ci sono misurazioni
                renderUserInfo("Peso", null, "Nessuna misurazione disponibile")}

            {renderUserInfo(
              "Altezza",
              null,
              selectedUser.altezza ? `${selectedUser.altezza} cm` : null
            )}
            {renderUserInfo(
              "Giorni",
              <FaEdit
                className="text-indigo-500 ml-4 cursor-pointer"
                size={20}
                onClick={() => setIsModalOpen(true)}
                title="Modifica Giorni"
              />,
              selectedUser.giorni ? `${selectedUser.giorni}` : "N/A"
            )}
          </div>
          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white rounded-lg p-6 w-full md:w-1/4 shadow-lg">
                <h2 className="text-2xl font-bold mb-4 text-center">
                  Modifica Giorni
                </h2>
                <label className=" italic text-md ">
                  Numero di giorni abilitati
                </label>
                <div className="flex flex-col items-center space-y-4 w-full mt-1">
                  {/* Input al centro con larghezza dinamica */}
                  <input
                    type="number"
                    min="1"
                    max="5"
                    value={giorni}
                    onChange={(e) => setGiorni(e.target.value)} // Funzione che gestisce l'evento
                    className="border border-gray-300 rounded px-2 py-1 text-lg w-full text-center"
                  />
                </div>
                {giorniError && (
                  <p className="text-red-500 text-sm mb-4 text-center">
                    {giorniError}
                  </p>
                )}
                {/* Bottoni con larghezza pari all'intero contenitore */}
                <div className="flex space-x-2 mt-4">
                  <button
                    onClick={saveGiorni}
                    className="flex-1 px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
                  >
                    Salva
                  </button>
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className="flex-1 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                  >
                    Annulla
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* Sezione per le misurazioni */}
          {selectedUser.MisurazionePeso &&
          selectedUser.MisurazionePeso.length > 0 ? (
            <div className="mt-16">
              <h2 className="text-3xl font-bold">Storico Misurazioni Peso</h2>

              {/* Navigazione tra i mesi */}
              <div className="flex justify-center mt-4 mb-4">
                <h3 className="text-xl font-semibold">
                  {getMonthName(currentMonth)} {currentYear}
                </h3>
              </div>

              <ul className="list-disc pl-5 mt-4">
                {filteredMisurazioni.length > 0 ? (
                  filteredMisurazioni
                    .slice()
                    .reverse() // Inverti l'ordine per mostrare le più recenti prima
                    .map((misurazione, idx) => (
                      <div
                        key={idx}
                        className="flex items-center bg-gray-100 p-4 rounded-lg shadow-sm space-x-3 mb-2"
                      >
                        <img
                          src="/icons8-balance-64.png"
                          alt="Peso"
                          className="w-10 h-10"
                        />
                        <div className="flex flex-col text-xl flex-grow">
                          <p>
                            <strong>Peso:</strong> {misurazione.peso} kg
                          </p>
                          <p>
                            <strong>Data di misurazione:</strong>{" "}
                            {formatDate(misurazione.data)}
                          </p>
                        </div>
                        <div className="flex justify-end">
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Evita il propagation del click
                              // Chiama la funzione per eliminare
                            }}
                            className="text-red-500 hover:text-red-700 focus:outline-none"
                            aria-label="Delete Misurazione"
                          >
                            <FaTrash size={28} />
                          </button>
                        </div>
                      </div>
                    ))
                ) : (
                  <p>Nessuna misurazione disponibile per questo mese</p>
                )}
              </ul>
              {/* Navigazione tra i mesi */}
              <div className="flex justify-between mt-8 mb-4 pl-4">
                <button
                  onClick={() => changeMonth(-1)}
                  className="px-4 py-2 text-white bg-indigo-500 hover:bg-indigo-600 rounded"
                >
                  Mese precedente
                </button>

                <button
                  onClick={() => changeMonth(1)}
                  className="px-4 py-2 text-white  bg-indigo-500 hover:bg-indigo-600 rounded"
                >
                  Mese successivo
                </button>
              </div>
            </div>
          ) : (
            <p>Nessuna misurazione disponibile</p>
          )}

          {lastLogin && (
            <p>
              <strong>Ultimo accesso:</strong>{" "}
              {new Date(lastLogin).toLocaleString()}
            </p>
          )}

          {/* Sezione Risposte */}
          <h2 className="text-3xl font-bold mt-16">Risposte Aggiuntive</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
            {renderField("Dieta passata?", selectedUser.risposte?.dieta)}
            {renderField(
              "Infortunio passato?",
              selectedUser.risposte?.infortunio
            )}
            {renderField(
              "Problematiche alimentari?",
              selectedUser.risposte?.problematicheAlimentari
            )}
            {renderField(
              "Obiettivo Breve Termine",
              selectedUser.risposte?.obiettivoBreveTermine
            )}
            {renderField(
              "Obiettivo Lungo Termine",
              selectedUser.risposte?.obiettivoLungoTermine
            )}
            {renderField(
              "Orario Allenamento",
              selectedUser.risposte?.orarioAllenamento
            )}
            {renderField("Carattere", selectedUser.risposte?.carattere)}
            {renderField(
              "Distretti Muscolari",
              selectedUser.risposte?.distrettiMuscolari
            )}
            {renderField("Sport Passati", selectedUser.risposte?.sportPassati)}
            {renderField("Integratori", selectedUser.risposte?.integratori)}
            {renderField(
              "Alimenti Fastidiosi",
              selectedUser.risposte?.alimentiFastidiosi
            )}
            {renderField(
              "Alimenti Indispensabili",
              selectedUser.risposte?.alimentiIndispensabili
            )}
            {renderField(
              "Accumulo Grasso",
              selectedUser.risposte?.accumuloGrasso
            )}
            {renderField(
              "Dimagrimento Zone",
              selectedUser.risposte?.dimagrimentoZone
            )}
            {renderField("Acqua", selectedUser.risposte?.acqua)}
            {renderField("Weekend Cene", selectedUser.risposte?.weekendCene)}
          </div>
        </div>
      )}
      <div className="flex justify-center mt-4 pt-6 pb-6">
        <button
          onClick={closeUserInfo}
          className="w-1/2 px-4 py-2 text-2xl text-indigo-500 bg-white hover:bg-indigo-500 hover:text-white rounded focus:outline-none border border-white"
        >
          Torna alla Home
        </button>
      </div>
    </div>
  );
};

export default ViewInformations;
