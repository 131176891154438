import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import LoadingAnimation from "../../assets/lottie/wired-outline-667-biking-hover-pinch.json";
import Warning from "../../assets/lottie/wired-flat-1140-error-hover-oscillate.json";
import userIconUomo from "../../assets/icons8-avatar-uomo-96.png";
import userIconDonna from "../../assets/icons8-avatar-donna-96.png";
import { FaTrash } from "react-icons/fa";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { getAuth } from "firebase/auth";
import Swal from "sweetalert2";

const ViewWeight = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const location = useLocation();
  const { misurazioni, anamnesiData } = location.state || {}; // Recupera i dati passati tramite state
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [misurazioniState, setMisurazioni] = useState(misurazioni || []); // Gestione dello stato delle misurazioni

  const handleDelete = async (misurazioneDaEliminare) => {
    try {
      const result = await Swal.fire({
        title: "Sei sicuro di voler eliminare il peso?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Elimina",
        denyButtonText: "Annulla",
        confirmButtonColor: "red",
        denyButtonColor: "green",
      });
      if (result.isConfirmed) {
        // Ottieni il riferimento al documento dell'utente
        const userDocRef = doc(db, "users", currentUser?.uid);
        console.log(userDocRef);

        // Rimuovi la misurazione dal campo 'MisurazionePeso' nel DB
        await updateDoc(userDocRef, {
          MisurazionePeso: arrayRemove(misurazioneDaEliminare),
        });

        // Rimuovi la misurazione anche dallo stato per aggiornare la UI
        setMisurazioni((prevMisurazioni) =>
          prevMisurazioni.filter(
            (misurazione) => misurazione !== misurazioneDaEliminare
          )
        );

        Swal.fire(
          "Eliminato!",
          "La misurazione è stata eliminata con successo.",
          "success"
        );
      } else {
        console.log("Eliminazione annullata");
      }
    } catch (error) {
      Swal.fire(
        "Errore",
        "Si è verificato un errore durante l'eliminazione della misurazione.",
        "error"
      );
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Tempo di attesa per il caricamento

    return () => clearTimeout(timer); // Pulizia del timer
  }, []);

  // Sesso per l'icona maschile o femminile
  const userSex = anamnesiData?.sesso;
  console.log(userSex)
  const userIcon = userSex === "Maschile" ? userIconUomo : userIconDonna;

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Lottie
          animationData={LoadingAnimation}
          loop
          autoplay
          style={{ width: 200, height: 200 }}
        />
        <p className="mt-4 text-gray-600">Caricamento in corso...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col  items-center min-h-screen bg-gray-50 py-6 px-4">
      <div
        className={`w-full max-w-2xl bg-white rounded-xl shadow-lg p-6 flex flex-col ${
          misurazioniState && misurazioniState.length > 0 ? "flex-grow" : ""
        }`}
      >
        {/* Titolo centrato */}

        <div className="space-y-2 overflow-y-auto max-h-100 mb-6">
          {misurazioniState && misurazioniState.length > 0 ? (
            <>
              <div className="avatar-container mb-4">
                <img src={userIcon} alt="User Avatar" className="avatar-icon" />
              </div>
              <h4 className="text-2xl font-bold text-center text-gray-700 mb-4">
                Le mie misurazioni
              </h4>
              <label className="block text-center text-gray-500 mb-4">
                Dall'ultima aggiunta alla prima
              </label>

              {misurazioniState
                .slice()
                .reverse() // Inverti l'ordine
                .map((misurazione, idx) => (
                  <div
                    key={idx}
                    className="flex items-center bg-gray-100 p-4 rounded-lg shadow-sm space-x-3"
                  >
                    <img
                      src="/icons8-balance-64.png"
                      alt="Peso"
                      className="w-8 h-8"
                    />
                    <div className="flex flex-col text-sm flex-grow">
                      <p>
                        <strong>Peso:</strong> {misurazione.peso} kg
                      </p>
                      <p>
                        <strong>Data:</strong> {misurazione.data}
                      </p>
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Evita il propagation del click
                          handleDelete(misurazione); // Chiama la funzione per eliminare
                        }}
                        className="text-red-500 hover:text-red-700 focus:outline-none"
                        aria-label="Delete Misurazione"
                      >
                        <FaTrash size={28} />
                      </button>
                    </div>
                  </div>
                ))}
            </>
          ) : (
            <div className="flex flex-col items-center ">
              <Lottie
                animationData={Warning}
                loop
                autoplay
                style={{ width: 200, height: 200 }}
              />

              <div className="mt-4 text-gray-600">
                {" "}
                Nessuna misurazione disponibile
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={() => navigate("/home")} // Naviga alla pagina home
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
        >
          Torna indietro
        </button>
      </div>
    </div>
  );
};

export default ViewWeight;