import React, { useState, useEffect } from 'react';

const WeightSelector = ({ value, onChange }) => {
  const minWeight = 0;
  const maxWeight = 250;
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState(null);

  // Aggiorna lo stato dell'inputValue quando il valore prop cambia
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Gestisce il cambiamento del valore tramite lo slider
  const handleSliderChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setInputValue(newValue);
    onChange(newValue);
    setError(null); // Pulisce l'errore quando il valore è valido
  };

  // Gestisce il cambiamento del valore tramite il campo di input
  const handleInputChange = (e) => {
    let newValue = e.target.value;

    // Permette solo numeri, una virgola o un punto, ma non entrambi
    const validValue = newValue
      .replace(/[^0-9.,]/g, '') // Mantieni solo numeri, punti e virgole
      .replace(/(\..*?)\..*/g, '$1') // Mantieni solo un punto
      .replace(/(,.*?),.*/g, '$1'); // Mantieni solo una virgola

    // Verifica se ci sono entrambi i punti e le virgole
    const hasBoth = /[.,].*[.,]/.test(validValue);

    if (validValue === '') {
      // Permetti un campo vuoto
      setInputValue('');
      setError(null); // Non mostrare errore se il campo è vuoto
      onChange(null); // Segnala il valore null al componente genitore
    } else if (hasBoth) {
      setError('Inserisci solo una virgola o un punto, non entrambi.');
    } else {
      // Sostituisci la virgola con il punto per la conversione in numero
      const normalizedValue = validValue.replace(',', '.');
      const parsedValue = parseFloat(normalizedValue);

      if (isNaN(parsedValue)) {
        // Gestisci il caso in cui il valore non è un numero
        setError('Inserisci un valore numerico valido.');
      } else if (parsedValue < minWeight || parsedValue > maxWeight ) {
        // Verifica se il valore è fuori dai limiti
        setError('Valore non accettato.');
      } else {
        // Se il valore è valido e all'interno dei limiti
        setError(null);
        setInputValue(validValue);
        onChange(parsedValue);
      }
    }
  };

  // Formatta il valore per la visualizzazione
  const formattedValue = inputValue;

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="w-full flex items-center justify-between">
        <span className="text-sm">{minWeight} kg</span>
        <span className="text-sm">{maxWeight} kg</span>
      </div>
      <input
        type="range"
        min={minWeight}
        max={maxWeight}
        step={0.1}
        value={parseFloat(inputValue) || 0} // Usa il valore numerico solo per lo slider
        onChange={handleSliderChange}
        className="w-full "
      />
      <div className="flex items-center space-x-2">
        <input
          type="text" // Usa type="text" per consentire l'inserimento libero
          value={formattedValue} // Mostra il valore come stringa
          onChange={handleInputChange}
          className="text-xl font-bold text-center w-24"
        />
        <span className="text-xl font-bold">kg</span>
      </div>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  );
};

export default WeightSelector;
